import React from "react";
import flightBooking from '../assets/img/tr-image/svgImages/FlightBooking.svg';
import interNationalTour from '../assets/img/tr-image/Images/international-tour.jpg';
import domesticTour from '../assets/img/tr-image/Images/travel-with-lugagge-hat.jpg';
import { useNavigate } from "react-router-dom";
import url from "../Common/url";
const Display =()=>{
    const navigate = useNavigate();
    const redirectToSearchFligt=()=>{
        navigate(url.searchFlight);
    };
    const redirectToSearchTour=()=>{
        navigate(url.searchTour);
    };
    return(
        <>
            <section id="go_beyond_area" className="section_padding_top">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="heading_left_area">
                        <h2>Experience the world the way you’ve<span> always imagined</span></h2>
                        <h5>Discover the journey tailored just for you.</h5>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="top_details_four_item-c imagination_boxed">
                        <img src={flightBooking} alt="flight" />
                        <div className="top_details_posation-c">
                            <p className="text-title">Flight Tickets</p>
                            <button className="btn btn_theme btn_md" onClick={redirectToSearchFligt}>Book now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="top_details_four_item-c imagination_boxed">
                        <img src={domesticTour} alt="domestic-tour-image" />
                        <div className="top_details_posation-c">
                            <p className="text-title">Domestic Tour</p>
                            <button className="btn btn_theme btn_md" onClick={redirectToSearchTour}>Explore</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-2">
                    <div className="top_details_four_item-c imagination_boxed">
                        <img src={interNationalTour} alt="international-tour-image" />
                        <div className="top_details_posation-c">
                            <p className="text-title">International Tour</p>
                            <button className="btn btn_theme btn_md" onClick={redirectToSearchTour}>Explore</button>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="imagination_boxed">
                        <a href="top-destinations.html">
                            <img src="assets/img/imagination/imagination2.png" alt="img" />
                        </a>
                        <h3><a href="#!">Travel around<span>the world</span></a></h3>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div className="imagination_boxed">
                        <a href="top-destinations.html">
                            <img src="assets/img/imagination/imagination3.png" alt="img" />
                        </a>
                        <h3><a href="top-destinations.html">Luxury resorts<span>top deals</span></a></h3>
                    </div>
                </div> */}
            </div>
        </div>
             </section>
        </>
    )
}
export default Display;