
const url={
    'homePage':'/',
    'searchTour':'/tour-list',
    'viewTourDetails':'/tour-details',
    'searchFlight':'/search-flight',
    'flightbooking':'/flightbooking',
    'login':'/login',
    'signup':'/signup',
    'mytrips':'/mytrip',
    'rzpykey':'rzp_test_9LBqF0Me9nXUZu'
}
export default url;