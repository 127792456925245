import React from "react";
import destinatinIcon from '../assets/img/Icon/destination.png'
import bestPriceIcon from '../assets/img/Icon/bestPrice.png'
import supportIcon from '../assets/img/Icon/customer-service.png'
export default function Details(){
    return(
        <>
            <section id="width_offer_area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="width_offer_item">
                        <div class="width_offer_icon">
                            <img src={destinatinIcon} alt="icon" style={{height:'50px'}} />
                        </div>
                        <div class="width_offer_text">
                            <h3>100+ destinations</h3>
                            <p>Nostrud aliqua ipsum dolore velit labore nulla
                                fugiat nulla irure nostrud.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="width_offer_item">
                        <div class="width_offer_icon">
                            <img src={bestPriceIcon} alt="icon" style={{height:'50px'}}/>
                        </div>
                        <div class="width_offer_text">
                            <h3>Best price guarantee</h3>
                            <p>Nostrud aliqua ipsum dolore velit labore nulla
                                fugiat nulla irure nostrud.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="width_offer_item">
                        <div class="width_offer_icon">
                            <img src={supportIcon} alt="icon" style={{height:'50px'}}/>
                        </div>
                        <div class="width_offer_text">
                            <h3>Top quality customer support</h3>
                            <p>Nostrud aliqua ipsum dolore velit labore nulla
                                fugiat nulla irure nostrud.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}