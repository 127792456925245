import React, { useEffect, useState } from 'react'
import Header from '../Header';
import Booking from './Booking';

const Dashboard =()=>{
    const [isLogin,setIsLogin]=useState(false);
    const [activeComponent,setActiveComponent]=useState('SearchBooking');
    const checkLoginStatus=()=>{
          const localStorageData =localStorage.getItem('trLoginData');
          console.log('lo',localStorageData);
          if(localStorageData){
            setIsLogin(true);
            console.log('true')
          }
          else{
            setIsLogin(false);
          }
    };
    useEffect(()=>{
        checkLoginStatus()
    },[])
    return(
        <>
            <Header customizedHeader={1} isDashboard={1}/>
            <section id="dashboard_main_arae mt-1" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-13 col-lg-12 col-md-12 col-sm-12 mt-2">
                            <div className="dashboard_sidebar">
                                {/* <div className="dashboard_sidebar_user">
                                    <img src="assets/img/common/dashboard-user.png" alt="img" />
                                    <h3>Sherlyn Chopra</h3>
                                    <p><a href="tel:+00-123-456-789">+00 123 456 789</a></p>
                                    <p><a href="mailto:sherlyn@domain.com">sherlyn@domain.com</a></p>
                                </div> */}
                                <div className="dashboard_menu_area">
                                <Booking />
                                    {/* <ul>
                                        
                                        {isLogin ?
                                            <>
                                                <li><a href="dashboard.html" className="active"><i className="fas fa-tachometer-alt"></i>Dashboard</a></li>
                                                <li><a href="my-profile.html"><i className="fas fa-user-circle"></i>My profile</a></li>
                                                <li><a href="wallet.html"><i className="fas fa-wallet"></i>Wallet</a></li>
                                                <li><a href="notification.html"><i className="fas fa-bell"></i>Notifications</a></li>
                                                <li><a href="#!" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="fas fa-sign-out-alt"></i>Logout</a></li>

                                            </>
                                        :  
                                        <>
                                            <li> <div><i className="fas fa-sign-out-alt"></i><span className={`${activeComponent === 'SearchBooking' ? 'd-menu-active' :''}`}>Search Booking</span></div></li>
                                            <li> <div><i className="fas fa-sign-out-alt"></i><span className={`${activeComponent === 'Login' ? 'd-menu-active' :''}`}>Login</span></div></li>
                                            <li> <div><i className="fas fa-sign-out-alt"></i><span className={`${activeComponent === 'SignUp' ? 'd-menu-active' :''}`}>Sign Up</span></div></li>
                                        </> 
                                        
                                        }
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-8 col-lg-8 col-md-9 col-sm-12 mt-2'>
                            {activeComponent === 'SearchBooking' && <Booking />}
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
export default Dashboard;