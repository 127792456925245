const apiService = {
    // baseUrl: 'https://localhost:7191/api/',
    baseUrl: 'https://apiservice.docotrip.com/api/',
  
    handleResponse: async function(response) {
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error: ${response.status} - ${errorData.message}`);
        }
        return response.json();
    },

    get: async function(endpoint) {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        return await this.handleResponse(response);
      } catch (error) {
        console.error('Error with GET request:', error);
      }
    },
  
    post: async function(endpoint, data) {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        console.log('post response',response)
        return await response.json();
      } catch (error) {
        console.error('Error with POST request:', error);
      }
    },
    postMedia: async function(endpoint, data) {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'POST',
          headers: {
            "accept": "*/*",
          },
          body:data
        });
        console.log('post response',response)
        return await response.json();
      } catch (error) {
        console.error('Error with POST request:', error);
      }
    },
  
    put: async function(endpoint, data) {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        return await response.json();
      } catch (error) {
        console.error('Error with PUT request:', error);
      }
    },
  
    delete: async function(endpoint) {
      try {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        return await response.json();
      } catch (error) {
        console.error('Error with DELETE request:', error);
      }
    }

  };

  export default apiService;
  
  // Usage example:
//   (async () => {
//     const getData = await apiService.get('/endpoint');
//     console.log(getData);
  
//     const postData = await apiService.post('/endpoint', { key: 'value' });
//     console.log(postData);
  
//     const putData = await apiService.put('/endpoint', { key: 'new value' });
//     console.log(putData);
  
//     const deleteData = await apiService.delete('/endpoint');
//     console.log(deleteData);
//   })();
  