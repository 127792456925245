const CommunicationHub={
brandName:"Doco Trip",
address:"201 2nd Floor, Ace Airosa Complex, Lucknow",
 confirmBooking:'9120861339',
 contactMail:'docotrip@gmail.com',
 supportMail:'support@docotrip.com',
 landLineNo:'+05224575101',
 whatsappNo:'9935775799',
 faceBookPageUrl:'https://www.facebook.com/Travel-Right-115728023216903/',
 instagramUrl:'https://www.instagram.com/travelright_lko/?gshid=YmMyMTA2M2Y',
 aboutUs:'We are a one-stop travel agency that will help you while visiting various places whether in a short time or long time as well as solo or in a group.We are a very experienced travel provider, and many travelers love to travel with us.',


}
export default CommunicationHub;