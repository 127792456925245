import { useEffect, useRef, useState } from "react";
import ScrollToTop from "../../scrollToTop";
import Header from "../../Header";
import apiService from "../../../Common/ApiFactory/apiService";
import url from "../../../Common/url";
import { useNavigate } from "react-router-dom";
import Common from "../../../Common/Common";
import UseResetScroll from "../../../Common/AutoScroll/UseResetScroll";
import { Button, Modal } from "react-bootstrap";
import travel from '../../../assets/img/tr-image/Gif/travel.gif'
const FlightTicket=()=>{
    const navigate = useNavigate();
    const locationDropdownRef = useRef(null);
    const categoryDropdownRef = useRef(null);
    const fromSectorDropdownRef = useRef(null);
    const toSectorDropdownRef = useRef(null);
    const[showLocationDrpdown,setShowLocationDrpdown]=useState(false);
    const[showFromSectorDrpdown,setShowFromSectorDrpdown]=useState(false);
    const[showToSectorDrpdown,setShowToSectorDrpdown]=useState(false);
    const[searchQuery,setSearchQuery]=useState('');
    const[locationList,setLocationList]=useState([]);
    const[categoryList,setCategoryList]=useState([]);
    const[fromSectorList,setFromSectorList]=useState([]);
    const[toSectorList,setToSectorList]=useState([]);
    const[selectedCategory,setSelectedCategory]=useState(0);
    const[selectedCategoryName,setSelectedCategoryName]=useState('');
    const[showCategoryDrpdown,setShowCategoryDrpdown]=useState(false);
    const[isApiCall,setIsApiCall]=useState(0);
    const[isFromSectorApiCall,setIsFromSectorApiCall]=useState(0);
    const[isToSectorApiCall,setIsToSectorApiCall]=useState(0);
    const[selectedFromSectorName,setSelectedFromSectorName]=useState("LKO - Lucknow");
    const[selectedFromSectorID,setSelectedFromSectorID]=useState(1);
    const[selectedToSectorName,setSelectedToSectorName]=useState("DXB - Dubai");
    const[selectedToSectoID,setSelectedToSectorID]=useState(2);
    const[searchKeyFromSector,setSearchKeyFromSector]=useState("");
    const[searchKeyToSector,setSearchKeyToSector]=useState("");
    const[fromDate,setFromDate]=useState("");
    const[toDate,setToDate]=useState("");
    const[isSearching,setIsSearching]=useState(false);
    const[flightTicketList,setFlightTicketList]=useState([]);
    const[ticketListForFilter,setTicketListForFilter]=useState([]);
    const[airlineList,setAirlineList]=useState([]);
    const[selectedAirline,setSelectedAirline]=useState([]);

    const [show, setShow] = useState(false);
    const getLocationList=async()=>{
        try{
            const {status,responseValue}= await apiService.get(`SearchEngine/SearchLocation?KeyWord=${searchQuery}`);
            if (status === 1){
                setLocationList(responseValue);
                if(responseValue.length > 0){
                    setShowLocationDrpdown(true);
                }
                else{
                    setShowLocationDrpdown(false);
                }
                
            }
            else{
                setShowLocationDrpdown(false);
            }
        }
        catch(error){
            console.error('Error Get Location List->',error);
        } 
    };
    const getCategoryList = async () => {
        try{
            const {status,responseValue}= await apiService.get(`Admin/GetCategoryList`);
            if (status === 1)setCategoryList(responseValue);
        }
        catch(error){
            console.error('Error Get Category List->',error);
        } 
    };
    const getAirlineList = async () => {
        try{
            const {status,responseValue}= await apiService.get(`Admin/AirlineDirectory`);
            if (status === 1)setAirlineList(responseValue);
        }
        catch(error){
            console.error('Error Get Category List->',error);
        } 
    };
    const handleSearch=(e)=>{
        const value = e.target.value;
        setSearchQuery(value);
    };
    const handleSelectLocation =(param)=>{
        document.getElementById('searchLocation').value=param;
        setShowLocationDrpdown(false);
    };
    const handleSearchTour =()=>{
        const searchQuery=document.getElementById('searchLocation').value;
        const encodedString=btoa(searchQuery);
        const urlParam=`search-tour=${encodedString}`;
        const urlParam2=`category=${selectedCategory}`;
        const urlParam3=`categoryName=${selectedCategoryName}`;
        const searchUrl=`${url.searchTour}?${urlParam}&${urlParam2}&${urlParam3}`;
        navigate(searchUrl);
    };
    const handleSelectCategory=(value)=>{
        setSelectedCategory(value.id);
        setSelectedCategoryName(value.category_name);
        setShowCategoryDrpdown(false);
    };
    const handleSearchSector=async(value,type)=>{
        if(type === 1){
            setSelectedFromSectorID(0);
            setSelectedFromSectorName(value);
            setSearchKeyFromSector(value);
        }
        else{
            setSelectedToSectorID(0);
            setSelectedToSectorName(value);
            setSearchKeyToSector(value);
        }
    };
    const getSectorList=async(value,type)=>{
            const{status,responseValue}= await apiService.get(`SearchEngine/SearchSector?KeyWord=${value}`);
            if(status === 1){
                if(type === 1){
                    setFromSectorList(responseValue);
                    setShowFromSectorDrpdown(true);
                }
                else{
                    setToSectorList(responseValue);
                    setShowToSectorDrpdown(true);
                }
            }
            else{
                if(type === 1){
                    if(value.length === 0){
                        setShowFromSectorDrpdown(false);
                    }
                    setFromSectorList([]);
                }
                else{
                    if(value.length === 0){
                        setShowToSectorDrpdown(false);
                    }
                    setToSectorList([]);
                }
                
            }
    }
    const handleSelectSector=(type,data)=>{
        if(type === 1){
            setSelectedFromSectorName(`${data.sectorCode} - ${data.sectorName}`);
            setSelectedFromSectorID(data.id);
            setShowFromSectorDrpdown(false);
        }
        else if(type === 2){
            setSelectedToSectorName(`${data.sectorCode} - ${data.sectorName}`);
            setSelectedToSectorID(data.id);
            setShowToSectorDrpdown(false);
        }
    };
    const handlSetDate=()=>{
        const currentDate = new Date();
        const yearCurrent = currentDate.getFullYear();
        const monthCurrent = String(currentDate.getMonth() + 1).padStart(2, '0');
        const dayCurrent = String(currentDate.getDate()).padStart(2, '0');
        const formattedCurrentDate = `${yearCurrent}-${monthCurrent}-${dayCurrent}`;
        // Get the future date (3 days later)
        const futureDate = new Date(currentDate);
        futureDate.setDate(futureDate.getDate() + 3); // Add 3 days
        const yearFuture = futureDate.getFullYear();
        const monthFuture = String(futureDate.getMonth() + 1).padStart(2, '0');
        const dayFuture = String(futureDate.getDate()).padStart(2, '0');
        const formattedFutureDate = `${yearFuture}-${monthFuture}-${dayFuture}`;
        setFromDate(formattedCurrentDate);
        setToDate(formattedFutureDate);
    };
    UseResetScroll();
    //Use Effect
    useEffect(()=>{
        getCategoryList();
        getAirlineList();
    },[]);
    useEffect(()=>{
        if(isApiCall){
            let timeout= setTimeout(()=>{
                getLocationList();
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsApiCall(1);
        
    },[searchQuery]);
    useEffect(()=>{
        if(isFromSectorApiCall){
            let timeout= setTimeout(()=>{
                getSectorList(searchKeyFromSector,1);
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsFromSectorApiCall(1); 
    },[searchKeyFromSector]);
    useEffect(()=>{
        if(isToSectorApiCall){
            let timeout= setTimeout(()=>{
                getSectorList(searchKeyToSector,2);
            },500);
            return ()=>clearTimeout(timeout);
        }
        setIsToSectorApiCall(1); 
    },[searchKeyToSector]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (locationDropdownRef.current && !locationDropdownRef.current.contains(event.target)) {
                setShowLocationDrpdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [locationDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target)) {
                setShowCategoryDrpdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [categoryDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (fromSectorDropdownRef.current && !fromSectorDropdownRef.current.contains(event.target)) {
                setShowFromSectorDrpdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [fromSectorDropdownRef]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (toSectorDropdownRef.current && !toSectorDropdownRef.current.contains(event.target)) {
                setShowToSectorDrpdown(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toSectorDropdownRef]);
    //-----------------------------------------------------//
    const handleCheckRequestUrl= async()=>{
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        if(param.size){
            const key = param.get("data");
            Common.removeFlightSearchData();
            if(key){
                try{
                    const decodedString=atob(key);
                    try{
                        const searchRequest=JSON.parse(decodedString);
                        setSelectedFromSectorID(searchRequest.fromSectorID);
                        setSelectedFromSectorName(searchRequest.fromSectorName);
                        setSelectedToSectorID(searchRequest.toSectorID);
                        setSelectedToSectorName(searchRequest.toSectorName);
                        setFromDate(searchRequest.fromDate);
                        setToDate(searchRequest.toDate);
                        // Used Search Flight on page load
                        setIsSearching(true);
                       
                        const obj={
                            departureSectorID:searchRequest.fromSectorID,
                            departureSectorName:(searchRequest.fromSectorName.split('-')[0]).trim(),
                            arrivalSectorID:searchRequest.toSectorID,
                            arrivalSectorName:(searchRequest.toSectorName.split('-')[0]).trim(),
                            fromDate:searchRequest.fromDate,
                            toDate:searchRequest.toDate
                        };
                        try{
                        const {responseValue} = await apiService.get(`SearchEngine/RetrieveFlightsByRouteAndDate?DepartureSectorID=${obj.departureSectorID}&ArrivalSectorID=${obj.arrivalSectorID}&DepartureSectorName=${obj.departureSectorName}&ArrivalSectorName=${obj.arrivalSectorName}&FromDate=${obj.fromDate}&ToDate=${obj.toDate}`);
                        setFlightTicketList(responseValue);
                        setTicketListForFilter(responseValue);
                        setIsSearching(false);
                        }
                        catch(error){
                            console.error('Error While Search Flight Err->',error);
                            setIsSearching(false);
                        }

                    }
                    catch(error){
                        console.error('Error While Parsing URL Req Err->',error);
                        handlSetDate();
                        setIsSearching(false);
                    }                    
                }
                catch(error){
                    console.error('Error While Decoding Url Req Err->',error);
                    handlSetDate();
                    setIsSearching(false);
                }
            }
            else{
                handlSetDate();
                setIsSearching(false);
            } 
        }
        else{
            const checkFlightData=sessionStorage.getItem("flightSearchData");
            if(checkFlightData){
                const getFlightData= JSON.parse(decodeURIComponent(checkFlightData));
                setSelectedFromSectorName(getFlightData.fromSectorName);
                setSelectedFromSectorID(getFlightData.fromSectorID);
                setSelectedToSectorName(getFlightData.toSectorName);
                setSelectedToSectorID(getFlightData.toSectorID);
                setSearchKeyFromSector(getFlightData.searchKeyFromSector);
                setSearchKeyToSector(getFlightData.searchKeyToSector);
                setFromDate(getFlightData.fromDate);
                setToDate(getFlightData.toDate);
                setFlightTicketList(getFlightData.ticketList);
                setTicketListForFilter(getFlightData.ticketList);
                setIsFromSectorApiCall(0);
                setIsToSectorApiCall(0);
            }
            else{
                handlSetDate();
                setIsSearching(false);
                showTicktList();
            }
           
        }
        
    };
    const handleSearchFlight=async(e)=>{
        e.preventDefault();
        setIsSearching(true);
        Common.removeFlightSearchData();
        const obj={
            departureSectorID:selectedFromSectorID,
            departureSectorName:(selectedFromSectorName.split('-')[0]).trim(),
            arrivalSectorID:selectedToSectoID,
            arrivalSectorName:(selectedToSectorName.split('-')[0]).trim(),
            fromDate:fromDate,
            toDate:toDate
        };
        try{
           const {responseValue} = await apiService.get(`SearchEngine/RetrieveFlightsByRouteAndDate?DepartureSectorID=${obj.departureSectorID}&ArrivalSectorID=${obj.arrivalSectorID}&DepartureSectorName=${obj.departureSectorName}&ArrivalSectorName=${obj.arrivalSectorName}&FromDate=${obj.fromDate}&ToDate=${obj.toDate}`);
           setFlightTicketList(responseValue);
           setTicketListForFilter(responseValue);
           setIsSearching(false);
        }
        catch(error){
            console.error('Error While Search Flight Err->',error);
            setIsSearching(false);
        }
        
    };
    const handleBookFlight=(data)=>{
        const isLogin = Common.isUserAuthenticated();
        if(isLogin){
            const flightData={
                fromSectorName:selectedFromSectorName,
                fromSectorID:selectedFromSectorID,
                toSectorName:selectedToSectorName,
                toSectorID:selectedToSectoID,
                searchKeyFromSector:searchKeyFromSector,
                searchKeyToSector:searchKeyToSector,
                fromDate:fromDate,
                toDate:toDate,
                ticketList:flightTicketList
            };
            sessionStorage.setItem('flightSearchData',encodeURIComponent(JSON.stringify(flightData)));
            const getEncodedData=Common.encodedData(JSON.stringify(data));
            navigate(`${url.flightbooking}?data=${getEncodedData}`);
        }
        else{
            setShow(true);
        }
        
    };
    const redirectToLogin=()=>{
        navigate(url.login)
    };
    const showTicktList=async()=>{  
        Common.removeFlightSearchData();
        setIsSearching(true);
        const obj={
            departureSectorID:selectedFromSectorID,
            departureSectorName:(selectedFromSectorName.split('-')[0]).trim(),
            arrivalSectorID:selectedToSectoID,
            arrivalSectorName:(selectedToSectorName.split('-')[0]).trim(),
            fromDate:fromDate,
            toDate:toDate
        };
        try{
        const {responseValue} = await apiService.get(`SearchEngine/RetrieveFlightsByRouteAndDate?DepartureSectorID=${obj.departureSectorID}&ArrivalSectorID=${obj.arrivalSectorID}&DepartureSectorName=${obj.departureSectorName}&ArrivalSectorName=${obj.arrivalSectorName}&FromDate=${obj.fromDate}&ToDate=${obj.toDate}`);
        setFlightTicketList(responseValue);
        setTicketListForFilter(responseValue);
        setIsSearching(false);
        }
        catch(error){
            console.error('Error While Search Flight Err->',error);
            setIsSearching(false);
        }
    };
    const handleCheckUncheckAirline=(param)=>{
        try{
            let tempArr=[...selectedAirline];
            const isChecked=document.getElementById(`airlineCheck${param}`).checked;
            if(isChecked){
                if(!tempArr.includes(param)){
                    tempArr.push(param);
                }
            }
            else{
                tempArr = tempArr.filter(airline=>airline !== param);
            }
            setSelectedAirline(tempArr);
        }
        catch(error){
            console.error('Error While Select Airline for filter Err:',error);
        }
        
    };
    const handleApplyFilter=()=>{
        //For price sorting {1 for low to high} {2 for high to low}
        try{
            if(selectedAirline.length > 0){
                const arr =[...ticketListForFilter];
                let filteredData = arr.filter(ticket=> selectedAirline.includes(ticket.airlineID));
                const sortingValue = document.getElementById("ddlSorting").value;
                if(parseInt(sortingValue) === 1 || parseInt(sortingValue) === 2){
                    if(parseInt(sortingValue) === 1){
                        filteredData=filteredData.sort((a,b)=>a.normalTicketFare - b.normalTicketFare);
                    }
                    else if(parseInt(sortingValue) === 2){
                        filteredData=filteredData.sort((a,b)=>b.normalTicketFare - a.normalTicketFare);
                    }
                }
                setFlightTicketList(filteredData);
            }
            else{
                setFlightTicketList(ticketListForFilter);
            }
        }   
        catch(error){
            console.error('Errow While Applying Filter Err:',error);
        }
        
       
    };
    const handleExchangeSector=()=>{
        setSelectedFromSectorName(selectedToSectorName);
        setSelectedFromSectorID(selectedToSectoID);
        setSelectedToSectorName(selectedFromSectorName);
        setSelectedToSectorID(selectedFromSectorID);
    }
    useEffect(()=>{
        handleCheckRequestUrl();
        document.title="Flight Results"
    },[]);
return(
    <>
    <Header customizedHeader={1} isDashboard={0} isFromSearchFlight={1}/>
    {/* Finder New */}
    {/* <section id="theme_search_form" className="flight-search"> */}
        <div className="container mt-2">
            <div className="row">
                <div className="col-lg-12">
                    <div className="theme_search_form_area" style={{padding:'30px 30px 10px 30px'}}>
                        <div className="theme_search_form_tabbtn" style={{marginBottom:'15px'}}>
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="flights-tab" data-bs-toggle="tab"
                                        data-bs-target="#flights" type="button" role="tab" aria-controls="flights"
                                        aria-selected="true"><i className="fas fa-plane-departure"></i>Flights</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="tours-tab" data-bs-toggle="tab" data-bs-target="#tours"
                                        type="button" role="tab" aria-controls="tours" aria-selected="false"><i
                                            className="fas fa-globe"></i>Tours</button>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="flights" role="tabpanel"
                                aria-labelledby="flights-tab">
                                <div className="tab-content" id="myTabContent1">
                                    <div className="tab-pane fade show active" id="oneway_flight" role="tabpanel"
                                        aria-labelledby="oneway-tab">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="oneway_search_form">
                                                    <form action="#!">
                                                        <div className="row">
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div className="flight_Search_boxed"  ref={fromSectorDropdownRef}>
                                                                    <p>From</p>
                                                                    <input type="text" value={selectedFromSectorName} onChange={(e)=>{handleSearchSector(e.target.value,1)}}/>
                                                                    {showFromSectorDrpdown ?
                                                                        <div class="dropdown">
                                                                            <div class="dropdown-contentNew">
                                                                                {fromSectorList && fromSectorList.map((list)=>{
                                                                                    return(
                                                                                        <>
                                                                                            <div className="row" onClick={()=>{handleSelectSector(1,list)}}>
                                                                                            <div className="cus-w-20 sector-code-btn-p"><button type="button" className="btn sector-code-btn">{list.sectorCode}</button></div>
                                                                                            <div className="cus-w-80">
                                                                                            <span className="sector-Name">{list.sectorName}</span>
                                                                                            <span className="sector-code-c">{list.sectorLocation}</span>
                                                                                            </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    :''}
                                                                    <div className="plan_icon_posation">
                                                                        <i className="fas fa-plane-departure"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div className="flight_Search_boxed" ref={toSectorDropdownRef}>
                                                                    <p>To</p>
                                                                    <input type="text" value={selectedToSectorName} onChange={(e)=>{handleSearchSector(e.target.value,2)}}/>
                                                                    {showToSectorDrpdown ?
                                                                        <div class="dropdown">
                                                                            <div class="dropdown-contentNew">
                                                                            {toSectorList && toSectorList.map((list)=>{
                                                                                    return(
                                                                                        <>
                                                                                            <div className="row" onClick={()=>{handleSelectSector(2,list)}}>
                                                                                            <div className="cus-w-20 sector-code-btn-p"><button type="button" className="btn sector-code-btn">{list.sectorCode}</button></div>
                                                                                            <div className="cus-w-80">
                                                                                            <span className="sector-Name">{list.sectorName}</span>
                                                                                            <span className="sector-code-c">{list.sectorLocation}</span>
                                                                                            </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    :''}
                                                                    <div className="plan_icon_posation">
                                                                        <i className="fas fa-plane-arrival"></i>
                                                                    </div>
                                                                    <div className="range_plan cursor-pointer" onClick={handleExchangeSector}>
                                                                        <i className="fas fa-exchange-alt"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div className="form_search_date">
                                                                    <div className="flight_Search_boxed date_flex_area">
                                                                        <div className="Journey_date w-100">
                                                                            <p>From date</p>
                                                                            <input type="date" value={fromDate} onChange={(e)=>{setFromDate(e.target.value)}}/>
                                                                            
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                                                <div className="form_search_date">
                                                                    <div className="flight_Search_boxed date_flex_area">
                                                                        
                                                                        <div className="Journey_date w-100">
                                                                            <p>To date</p>
                                                                            <input type="date" value={toDate} onChange={(e)=>{setToDate(e.target.value)}}/>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="top_form_search_button">
                                                                <button className="btn btn_theme btn_md cus-search-button" disabled={isSearching} onClick={handleSearchFlight}>
                                                                 {isSearching ?
                                                                 <>
                                                                 <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                 Searching...
                                                                 </>
                                                                 :<>Search</>
                                                                 }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tours" role="tabpanel" aria-labelledby="tours-tab">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="tour_search_form">
                                            <form>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                        <div className="flight_Search_boxed" ref={locationDropdownRef}>
                                                            <p>Destination</p>
                                                            <input type="text" id="searchLocation" placeholder="Where are you going?" onChange={handleSearch}/>
                                                            {showLocationDrpdown ?
                                                                <div class="dropdown">
                                                                    <div class="dropdown-content">
                                                                        {locationList && locationList.map((list)=>{
                                                                            return(
                                                                                <>
                                                                                <span onClick={()=>{handleSelectLocation(list.location)}}>{list.location}</span>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            :''}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                                        <div className="form_search_date">
                                                            <div className="flight_Search_boxed date_flex_area finder-cate-dropdown">
                                                                <div className="Journey_date c-width-100 c-mx-auto"  ref={categoryDropdownRef}>
                                                                    <button className="form-control t-left finder-cate-btn finder-cate-heading" onClick={(e)=>{e.preventDefault();setShowCategoryDrpdown(!showCategoryDrpdown)}}>{selectedCategoryName || 'Choose Category'}</button>
                                                                    {showCategoryDrpdown ?
                                                                    <div class="dropdown">
                                                                        <div class="dropdown-content">
                                                                            {categoryList && categoryList.map((list)=>{
                                                                                return(
                                                                                    <>
                                                                                    <span onClick={()=>{handleSelectCategory(list)}}>{list.category_name}</span>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                    :''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="top_form_search_button">
                                                        <button className="btn btn_theme btn_md cus-search-button" onClick={handleSearchTour}>Search</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/* </section> */}
    {/* End Here */}
    <section id="explore_area" className="flight_list_padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 fiight-search-filter">
                    <div className="left_side_search_area">
                        <div className="row">
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Sort</h5>
                                </div>
                                <div className="filter-price">
                                <select className="form-control" id="ddlSorting">
                                        <option value="0">Choose</option>
                                        <option value="1">Low to High</option>
                                        <option value="2">High to Low</option>
                                </select>
                                </div>
                                <button className="apply" type="button" onClick={handleApplyFilter}>Apply</button>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Fare Type</h5>
                                </div>
                                <div className="tour_search_type">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt1" />
                                        <label className="form-check-label" htmlFor="flexCheckDefaultt1">
                                            <span className="area_flex_one">
                                                <span>Regular Fare</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultt2" />
                                        <label className="form-check-label" htmlFor="flexCheckDefaultt2">
                                            <span className="area_flex_one">
                                                <span>Group Fare</span>
                                                {/* <span>26</span> */}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="left_side_search_boxed">
                                <div className="left_side_search_heading">
                                    <h5>Airline</h5>
                                </div>
                                <div className="tour_search_type">
                                    {airlineList && airlineList.map((item,index)=>{
                                        return(
                                            <div className="form-check" key={index+1}>
                                                <input className="form-check-input" type="checkbox" id={`airlineCheck${item.id}`} onClick={()=>{handleCheckUncheckAirline(item.id,index)}}/>
                                                <label className="form-check-label" htmlFor={`airlineCheck${item.id}`}>
                                                    <span className="area_flex_one">
                                                        <span>{item.airline_name}</span>
                                                    </span>
                                                </label>
                                            </div>
                                        )
                                    })}                                    
                                </div>
                                <button className="apply" type="button" onClick={handleApplyFilter}>Apply</button>
                            </div>
                        </div>
                        {/* <div className="left_side_search_boxed">
                            <div className="left_side_search_heading">
                                <h5>Airlines</h5>
                            </div>
                            <div className="tour_search_type">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaults1" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaults1">
                                        <span className="area_flex_one">
                                            <span>Quatar Airways</span>
                                            <span>17</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaults2" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaults2">
                                        <span className="area_flex_one">
                                            <span>Fly Amirates </span>
                                            <span>14</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaults3" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaults3">
                                        <span className="area_flex_one">
                                            <span>Novo Air </span>
                                            <span>62</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaults4" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaults4">
                                        <span className="area_flex_one">
                                            <span>Air Asia </span>
                                            <span>08</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaults5" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaults5">
                                        <span className="area_flex_one">
                                            <span>Singapore Airlines </span>
                                            <span>12</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="left_side_search_boxed">
                            <div className="left_side_search_heading">
                                <h5>Refundable</h5>
                            </div>
                            <div className="tour_search_type">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultp1" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaultp1">
                                        <span className="area_flex_one">
                                            <span>Yes</span>
                                            <span>17</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultp2" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaultp2">
                                        <span className="area_flex_one">
                                            <span>No</span>
                                            <span>14</span>
                                        </span>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaultp3" />
                                    <label className="form-check-label" htmlFor="flexCheckDefaultp3">
                                        <span className="area_flex_one">
                                            <span>As per rules</span>
                                            <span>62</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* When no ticket found */}
                {flightTicketList.length === 0 ?
                    <div className="col-lg-6 text-center no-flights-message">
                        <img src={travel} style={{height:'150px'}} alt=""/>
                        <h5 className="mb-3">Sorry, no flights available for the selected route.</h5>
                    </div>
                :
                    
                    <div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="flight_search_result_wrapper flight-details-block">
                                    {flightTicketList && flightTicketList.map((value,index)=>{
                                        const connectionDetails=JSON.parse(value.connectionList);
                                        return(
                                            <div className="flight_search_item_wrappper">
                                                <div className="flight_search_items">
                                                    <div className="multi_city_flight_lists">
                                                        <div className="flight_multis_area_wrapper">
                                                            <div className="flight_search_left">
                                                                <div className="cus-flight_logo">
                                                                    <img src={value.airlineLogo} alt="img" />
                                                                </div>
                                                                <div className="flight_search_destination">
                                                                    <h4>{value.departureTime}</h4>
                                                                    <h5>{value.departureSectorCode}</h5>
                                                                    <h6>{value.departureSectorLocation}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="flight_search_middel">
                                                                <div className="flight_right_arrow">
                                                                    <i className="bi bi-arrow-right-circle-fill arrow-icon"></i>
                                                                    <h6>{value.flightStoppage}</h6>
                                                                    <p>{value.timeDifference}</p>
                                                                </div>
                                                                <div className="flight_search_destination">
                                                                    
                                                                    <h4>{value.arrivalTime}</h4>
                                                                    <h5>{value.arrivalSectorCode}</h5>
                                                                    <h6>{value.arrivalSectorLocation}</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="flight_search_right">
                                                        <div className="mb-2" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="25"
                                                                height="25"
                                                                fill="currentColor"
                                                                className="bi bi-currency-rupee"
                                                                viewBox="0 0 16 16"
                                                                style={{ verticalAlign: 'middle' }} // Align the icon vertically
                                                            >
                                                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                                            </svg>
                                                            <span className="ticket-price-lable">{value.normalTicketFare}</span>
                                                        </div>
                                                        {value.isSold ? <span className="btn btn-sm" disabled>out of stock</span>
                                                        
                                                         :<span className="btn btn_theme btn_sm" onClick={()=>{handleBookFlight(value)}}>Book now</span>
                                                        }

                                                        
                                                        
                                                        <h6 data-bs-toggle="collapse" data-bs-target={`#collapseFlightDetails${index}`}
                                                            ariaExpanded="false" aria-controls="collapseExample">Flight Details <i
                                                                className="fas fa-chevron-down color-common"></i></h6>
                                                    </div>
                                                </div>
                                                <div className="flight_policy_refund collapse mt-2" id={`collapseFlightDetails${index}`}>
                                                
                                                    {value.isConnectedFlight === 1 ?
                                                    <>
                                                        {connectionDetails && connectionDetails.length > 0 && connectionDetails.map((conn,ind,allConn)=>{
                                                            return(
                                                                    <>
                                                                        {ind === 0 ?
                                                                            <div className="flight_show_down_wrapper">
                                                                                <div className="flight-shoe_dow_item">
                                                                                    <div className="airline-details">
                                                                                        <div className="img"><img src={value.airlineLogo} alt="img" /></div>
                                                                                        <span className="airlineName fw-500">{value.airline_name}</span>
                                                                                        <span className="flightNumber"> {value.airline_no}</span>
                                                                                    </div>
                                                                                    <div className="flight_inner_show_component">
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">
                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {value.formattedDepartureDate}</span><br />
                                                                                                    <span className="time font-1rem"> {value.departureTime}</span> <br />
                                                                                                    <span className="time font-sec"> {value.departureSectorCode} - {value.departureSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {value.departureSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{value.departureTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="flight_duration">
                                                                                            <div className="arrow_right"></div>
                                                                                            <span> {conn.timeDifference ?? "7h 0m"}</span>
                                                                                        </div>
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">

                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {conn.arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight_refund_policy">
                                                                                    <div className="TabPanelInner flex_widht_less">
                                                                                        <h4>Refund Policy</h4>
                                                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                                                            following policies.</p>
                                                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                                            policy + DocoTrip Convenience Fee). </p>
                                                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                                                    </div>
                                                                                    <div className="TabPanelInner">
                                                                                        <h4>Baggage</h4>
                                                                                        <div className="flight_info_taable mt-1">
                                                                                            <p><span>{(conn.handbaggageWeight+conn.checkInBaggageWeight)}KG /</span> person</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        : 
                                                                            <div className="flight_show_down_wrapper">
                                                                                <div className="flight-shoe_dow_item">
                                                                                    <div className="airline-details">
                                                                                        <div className="img"><img src={value.airlineLogo} alt="img" /></div>
                                                                                        <span className="airlineName fw-500">{value.airline_name}</span>
                                                                                        <span className="flightNumber"> {value.airline_no}</span>
                                                                                    </div>
                                                                                    <div className="flight_inner_show_component">
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">
                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {allConn[(ind-1)].formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {allConn[(ind-1)].arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {allConn[(ind-1)].arrivalSectorCode} - {allConn[(ind-1)].arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {allConn[(ind-1)].arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{allConn[(ind-1)].arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="flight_duration">
                                                                                            <div className="arrow_right"></div>
                                                                                            <span> {allConn[(ind-1)].timeDifference}</span>
                                                                                        </div>
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">
                                                                                                {/* <div className="code_time">
                                                                                                    <span className="code"> {conn.arrivalSectorCode}</span>
                                                                                                    <span className="time"> {conn.arrivalTime}</span>
                                                                                                </div>
                                                                                                <p className="airport">{conn.arrivalTerminal} {conn.arrivalSectorLocation}</p>
                                                                                                <p className="date"> {conn.formattedArrivalDate}</p> */}
                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {value.arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight_refund_policy">
                                                                                    <div className="TabPanelInner flex_widht_less">
                                                                                        <h4>Refund Policy</h4>
                                                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                                                            following policies.</p>
                                                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                                            policy + DocoTrip Convenience Fee). </p>
                                                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                                                    </div>
                                                                                    <div className="TabPanelInner">
                                                                                        <h4>Baggage</h4>
                                                                                        <div className="flight_info_taable mt-1">
                                                                                            {/* <h3>DAC-SPD</h3> */}
                                                                                            <p><span>{(conn.handbaggageWeight+conn.checkInBaggageWeight)}KG /</span> person</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>              
                                                                        }
                                                                        {ind === (connectionDetails.length-1) ?
                                                                            <div className="flight_show_down_wrapper">
                                                                                <div className="flight-shoe_dow_item">
                                                                                    <div className="airline-details">
                                                                                        <div className="img"><img src={value.airlineLogo} alt="img" /></div>
                                                                                        <span className="airlineName fw-500">{value.airline_name}</span>
                                                                                        <span className="flightNumber"> {value.airline_no}</span>
                                                                                    </div>
                                                                                    <div className="flight_inner_show_component">
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">

                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {conn.arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="flight_duration">
                                                                                            <div className="arrow_right"></div>
                                                                                            <span> {conn.timeDifference ?? "3h 9m"}</span>
                                                                                        </div>
                                                                                        <div className="flight_det_wrapper">
                                                                                            <div className="flight_det">

                                                                                                <div className="code_time">
                                                                                                    <span className="code"> {value.formattedArrivalDate}</span><br />
                                                                                                    <span className="time font-1rem"> {value.arrivalTime}</span> <br />
                                                                                                    <span className="time font-sec"> {value.arrivalSectorCode} - {value.arrivalSectorName}</span>
                                                                                                </div>
                                                                                                <p className="airport mb-1">
                                                                                                    {value.arrivalSectorLocation}
                                                                                                    <br />
                                                                                                    <span className="font-sec">{value.arrivalTerminal} </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight_refund_policy">
                                                                                    <div className="TabPanelInner flex_widht_less">
                                                                                        <h4>Refund Policy</h4>
                                                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                                                            following policies.</p>
                                                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                                            policy + DocoTrip Convenience Fee). </p>
                                                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                                                    </div>
                                                                                    <div className="TabPanelInner">
                                                                                        <h4>Baggage</h4>
                                                                                        <div className="flight_info_taable mt-1">
                                                                                            {/* <h3>DAC-SPD</h3> */}
                                                                                            <p><span>{(conn.handbaggageWeight+conn.checkInBaggageWeight)}KG /</span> person</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> 
                                                                        :""}
                                                                    </>
                                                            );
                                                        })}
                                                    </>
                                                            
                                                    :
                                                        
                                                            <div className="flight_show_down_wrapper">
                                                                <div className="flight-shoe_dow_item">
                                                                    <div className="airline-details">
                                                                        <div className="img"><img src={value.airlineLogo} alt="img" /></div>
                                                                        <span className="airlineName fw-500">{value.airline_name}</span>
                                                                        <span className="flightNumber"> {value.airline_no}</span>
                                                                    </div>
                                                                    <div className="flight_inner_show_component">
                                                                        <div className="flight_det_wrapper">
                                                                            <div className="flight_det">
                                                                                <div className="code_time">
                                                                                    <span className="code"> {value.departureSectorCode}</span>
                                                                                    <span className="time"> {value.departureTime}</span>
                                                                                </div>
                                                                                <p className="airport">{value.departureTerminal} {value.departureSectorLocation}</p>
                                                                                <p className="date"> {value.formattedDepartureDate}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flight_duration">
                                                                            <div className="arrow_right"></div>
                                                                            <span> {value.timeDifference}</span>
                                                                        </div>
                                                                        <div className="flight_det_wrapper">
                                                                            <div className="flight_det">
                                                                                <div className="code_time">
                                                                                    <span className="code"> {value.arrivalSectorCode}</span>
                                                                                    <span className="time"> {value.arrivalTime}</span>
                                                                                </div>
                                                                                <p className="airport">{value.arrivalTerminal} {value.arrivalSectorLocation}</p>
                                                                                <p className="date"> {value.formattedArrivalDate}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flight_refund_policy">
                                                                    <div className="TabPanelInner flex_widht_less">
                                                                        <h4>Refund Policy</h4>
                                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                                            following policies.</p>
                                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                                            policy + DocoTrip Convenience Fee). </p>
                                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                                    </div>
                                                                    <div className="TabPanelInner">
                                                                        <h4>Baggage</h4>
                                                                        <div className="flight_info_taable mt-1">
                                                                            {/* <h3>DAC-SPD</h3> */}
                                                                            <p><span>{(value.handBaggageWeight+value.checkInBaggageWeight)}KG /</span> person</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                
                                    {/* <div className="flight_search_item_wrappper">
                                        <div className="flight_search_items">
                                            <div className="multi_city_flight_lists">
                                                <div className="flight_multis_area_wrapper">
                                                    <div className="flight_search_left">
                                                        <div className="flight_logo">
                                                            <img src="assets/img/common/biman_bangla.png" alt="img" />
                                                        </div>
                                                        <div className="flight_search_destination">
                                                            <p>From</p>
                                                            <h3>New York</h3>
                                                            <h6>JFK - John F. Kennedy International...</h6>
                                                        </div>
                                                    </div>
                                                    <div className="flight_search_middel">
                                                        <div className="flight_right_arrow">
                                                            <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                                            <h6>Non-stop</h6>
                                                            <p>01h 05minute </p>
                                                        </div>
                                                        <div className="flight_search_destination">
                                                            <p>To</p>
                                                            <h3>London </h3>
                                                            <h6>LCY, London city airport </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_search_right">
                                                <h5><del>$9,560</del></h5>
                                                <h2>$7,560<sup>*20% OFF</sup></h2>
                                                <a href="flight-booking-submission.html" className="btn btn_theme btn_sm">Book
                                                    now</a>
                                                <p>*Discount applicable on some conditions</p>
                                                <h6 data-bs-toggle="collapse" data-bs-target="#collapseExample2"
                                                    aria-expanded="false" aria-controls="collapseExample2">Show more <i
                                                        className="fas fa-chevron-down"></i></h6>
                                            </div>
                                        </div>
                                        <div className="flight_policy_refund collapse" id="collapseExample2">
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="flight_search_item_wrappper">
                                        <div className="flight_search_items">
                                            <div className="multi_city_flight_lists">
                                                <div className="flight_multis_area_wrapper">
                                                    <div className="flight_search_left">
                                                        <div className="flight_logo">
                                                            <img src="assets/img/common/biman_bangla.png" alt="img" />
                                                        </div>
                                                        <div className="flight_search_destination">
                                                            <p>From</p>
                                                            <h3>New York</h3>
                                                            <h6>JFK - John F. Kennedy International...</h6>
                                                        </div>
                                                    </div>
                                                    <div className="flight_search_middel">
                                                        <div className="flight_right_arrow">
                                                            <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                                            <h6>Non-stop</h6>
                                                            <p>01h 05minute </p>
                                                        </div>
                                                        <div className="flight_search_destination">
                                                            <p>To</p>
                                                            <h3>London </h3>
                                                            <h6>LCY, London city airport </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_search_right">
                                                <h5><del>$9,560</del></h5>
                                                <h2>$7,560<sup>*20% OFF</sup></h2>
                                                <a href="flight-booking-submission.html" className="btn btn_theme btn_sm">Book
                                                    now</a>
                                                <p>*Discount applicable on some conditions</p>
                                                <h6 data-bs-toggle="collapse" data-bs-target="#collapseExample3"
                                                    aria-expanded="false" aria-controls="collapseExample3">Show more <i
                                                        className="fas fa-chevron-down"></i></h6>
                                            </div>
                                        </div>
                                        <div className="flight_policy_refund collapse" id="collapseExample3">
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flight_search_item_wrappper">
                                        <div className="flight_search_items">
                                            <div className="multi_city_flight_lists">
                                                <div className="flight_multis_area_wrapper">
                                                    <div className="flight_search_left">
                                                        <div className="flight_logo">
                                                            <img src="assets/img/common/biman_bangla.png" alt="img" />
                                                        </div>
                                                        <div className="flight_search_destination">
                                                            <p>From</p>
                                                            <h3>New York</h3>
                                                            <h6>JFK - John F. Kennedy International...</h6>
                                                        </div>
                                                    </div>
                                                    <div className="flight_search_middel">
                                                        <div className="flight_right_arrow">
                                                            <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                                            <h6>Non-stop</h6>
                                                            <p>01h 05minute </p>
                                                        </div>
                                                        <div className="flight_search_destination">
                                                            <p>To</p>
                                                            <h3>London </h3>
                                                            <h6>LCY, London city airport </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_search_right">
                                                <h5><del>$9,560</del></h5>
                                                <h2>$7,560<sup>*20% OFF</sup></h2>
                                                <a href="flight-booking-submission.html" className="btn btn_theme btn_sm">Book
                                                    now</a>
                                                <p>*Discount applicable on some conditions</p>
                                                <h6 data-bs-toggle="collapse" data-bs-target="#collapseExample4"
                                                    aria-expanded="false" aria-controls="collapseExample4">Show more <i
                                                        className="fas fa-chevron-down"></i></h6>
                                            </div>
                                        </div>
                                        <div className="flight_policy_refund collapse" id="collapseExample4">
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flight_search_item_wrappper">
                                        <div className="flight_search_items">
                                            <div className="multi_city_flight_lists">
                                                <div className="flight_multis_area_wrapper">
                                                    <div className="flight_search_left">
                                                        <div className="flight_logo">
                                                            <img src="assets/img/common/biman_bangla.png" alt="img" />
                                                        </div>
                                                        <div className="flight_search_destination">
                                                            <p>From</p>
                                                            <h3>New York</h3>
                                                            <h6>JFK - John F. Kennedy International...</h6>
                                                        </div>
                                                    </div>
                                                    <div className="flight_search_middel">
                                                        <div className="flight_right_arrow">
                                                            <img src="assets/img/icon/right_arrow.png" alt="icon" />
                                                            <h6>Non-stop</h6>
                                                            <p>01h 05minute </p>
                                                        </div>
                                                        <div className="flight_search_destination">
                                                            <p>To</p>
                                                            <h3>London </h3>
                                                            <h6>LCY, London city airport </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_search_right">
                                                <h5><del>$9,560</del></h5>
                                                <h2>$7,560<sup>*20% OFF</sup></h2>
                                                <a href="flight-booking-submission.html" className="btn btn_theme btn_sm">Book
                                                    now</a>
                                                <p>*Discount applicable on some conditions</p>
                                                <h6 data-bs-toggle="collapse" data-bs-target="#collapseExample5"
                                                    aria-expanded="false" aria-controls="collapseExample5">Show more <i
                                                        className="fas fa-chevron-down"></i></h6>
                                            </div>
                                        </div>
                                        <div className="flight_policy_refund collapse" id="collapseExample5">
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flight_show_down_wrapper">
                                                <div className="flight-shoe_dow_item">
                                                    <div className="airline-details">
                                                        <div className="img"><img src="assets/img/icon/bg.png" alt="img" /></div>
                                                        <span className="airlineName fw-500">Biman Bangladesh Airlines &nbsp;
                                                            BG435</span>
                                                        <span className="flightNumber">BOEING 737-800 - 738</span>
                                                    </div>
                                                    <div className="flight_inner_show_component">
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                        <div className="flight_duration">
                                                            <div className="arrow_right"></div>
                                                            <span>01h 15m</span>
                                                        </div>
                                                        <div className="flight_det_wrapper">
                                                            <div className="flight_det">
                                                                <div className="code_time">
                                                                    <span className="code">DAC</span>
                                                                    <span className="time">15:00</span>
                                                                </div>
                                                                <p className="airport">Hazrat Shahjalal International Airport
                                                                </p>
                                                                <p className="date">7th Jun 2022</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flight_refund_policy">
                                                    <div className="TabPanelInner flex_widht_less">
                                                        <h4>Refund Policy</h4>
                                                        <p className="fz12">1. Refund and Date Change are done as per the
                                                            following policies.</p>
                                                        <p className="fz12">2. Refund Amount= Refund Charge (as per airline
                                                            policy + DocoTrip Convenience Fee). </p>
                                                        <p className="fz12">3. Date Change Amount= Date Change Fee (as per
                                                            Airline Policy + DocoTrip Convenience Fee).</p>
                                                    </div>
                                                    <div className="TabPanelInner">
                                                        <h4>Baggage</h4>
                                                        <div className="flight_info_taable">
                                                            <h3>DAC-SPD</h3>
                                                            <p><span>20KG /</span> person</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            
                            </div>
                        </div>
                    </div>
                }
                
            </div>
            
        </div>
        <div className="flight-filter-bottom fixed-bottom bg-white">
                <div className="row pb-3 d-flex justify-content-center">
                    <div className="col-3 mt-2 text-center">
                        <i className="bi bi-funnel-fill filter-icon f-30"></i>
                        <span className="flight-fliter-bottom-title">FILTER</span>
                    </div>
                    <div className="col-3 mt-2 text-center">
                    <svg width="1em" height="1em" font-size="2rem" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-testid="AirlinesFilledIcon" ><path fill-rule="evenodd" d="m3.007 5.0762 2.7498 14.066c.0608.4886.5144.8578 1.054.8578h12.209c.5523 0 .9966-.4554.8258-.9806-.1382-.4248-.3351-.8245-.502-1.1574-.2383-.4753-.606-.9557-1.066-1.2224-2.7776-1.6102-4.5703-2.5788-6.4255-5.0896L7.1924 4.8575c-.1542-.2088-.3918-.3545-.6621-.4062L4.2786 4.021c-.7077-.1352-1.3531.4004-1.2717 1.0552Z" clip-rule="evenodd"></path></svg>
                        <span className="filter-icon flight-fliter-bottom-title">AIRLINE</span>
                    </div>
                    <div className="col-3 mt-2 text-center">
                        <i className="bi bi-filter-left filter-icon f-30"></i>
                        <span className="flight-fliter-bottom-title">SORT</span>
                    </div>
                </div>
        </div>
        <Modal show={show} onHide={()=>{setShow(false)}} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You must be logged in to access this feature. Please log in to continue.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setShow(false)}}>
            Close
          </Button>
          <Button variant="primary" onClick={redirectToLogin}>
            Go to Login
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
    
    <ScrollToTop />
    </>
)
} 
export default FlightTicket;