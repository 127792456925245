import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import url from "../../Common/url";
import FadeLineLoader from '../../assets/img/loader/Fading-line.gif';
import '../Snackbar/style.css'
import apiService from "../../Common/ApiFactory/apiService";
const SignUp=()=>{
    const navigate = useNavigate();
    document.title="sign-up";
    const[showBtnLoder,setShowBtnLoder] = useState(false);
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const[sendForm,setSendForm]=useState({
        firstName:"",
        lastName:"",
        email:"",
        mobileNumber:"",
        password:"",
        confirmPassword:"",
    });
    const validationForm=(data)=>{
        const validationRes={key:'',isValid:true,message:"success"};
        if (!data.firstName.trim()) {
            validationRes.key = "firstName";
            validationRes.isValid = false;
            validationRes.message="This field is required.";
            
        }
        else if (!data.lastName.trim()) {
            validationRes.key = "lastName";
            validationRes.isValid = false;
            validationRes.message="This field is required.";
        }
        else if (data.email.trim()) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(data.email)) {
                validationRes.key = "email";
                validationRes.isValid = false;
                validationRes.message="Please enter a valid email address.";
            }
        }
        else if (!data.mobileNumber.trim()) {
            validationRes.key = "mobileNumber";
            validationRes.isValid = false;
            validationRes.message="This field is required.";
        } 
        else if (!/^\d{10}$/.test(data.mobileNumber)) {
            validationRes.key = "mobileNumber";
            validationRes.isValid = false;
            validationRes.message="Mobile number must be 10 digits long.";
            
        }
    
        else if (!data.password.trim()) {
            
            validationRes.key = "password";
            validationRes.isValid = false;
            validationRes.message="This field is required.";
        } 
        else if (data.password.length < 6) {
                validationRes.key = "password";
                validationRes.isValid = false;
                validationRes.message="Password must be at least 6 characters long.";
        }
    
        else if (data.password !== data.confirmPassword) {
            validationRes.key = "confirmPassword";
            validationRes.isValid = false;
            validationRes.message="Password and Confirm Password do not match.";
        }
        
        return validationRes; 
    };
    const handleChange=(e)=>{
        const {value,name}=e.target;
         const targetElement=document.getElementById(`${name}`);
         targetElement.classList.remove('is-invalid');
        if (name === "mobileNumber") {
            if(value.length < 11){
                const numericValue = value.replace(/[^0-9]/g, '');
                setSendForm((prev) => ({
                    ...prev,
                    [name]: numericValue 
                }));
            }
            
        } else {
            setSendForm((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };
    const handleSignUp=async(e)=>{
        e.preventDefault();
        const validation=validationForm(sendForm);
        if(validation.isValid){
            console.log('valid',sendForm);
            const obj={
                    "userName": "",
                    "mobileNo": sendForm.mobileNumber,
                    "email": sendForm.email,
                    "password": sendForm.confirmPassword,
                    "firstName": sendForm.firstName,
                    "lastName": sendForm.lastName
                 
            };
            try{
                setShowBtnLoder(true);
                const {status,responseValue,message}= await apiService.post('Authentication/SignUp',obj);
                if(status === 1){
                    setShowBtnLoder(false);
                    setSnackbarMessage(message);
                    setIsSnackbarVisible(true);
                    setTimeout(()=>{
                        setIsSnackbarVisible(false);
                        navigate(url.login);
                    },1500);
                }
                else{
                    console.error("Error While SignUp dberr:",message)
                    setShowBtnLoder(false);
                    setSnackbarMessage("Server Busy Try Again..!");
                    setIsSnackbarVisible(true);
                    setTimeout(()=>{
                        setIsSnackbarVisible(false)
                    },2000)
                }
            }
            catch(error){
                console.error('Error while SignUp:',error);
                setShowBtnLoder(false);
                setSnackbarMessage("Server Busy Try Again..!");
                setIsSnackbarVisible(true);
                setTimeout(()=>{
                    setIsSnackbarVisible(false)
                },2000)
            }
            
        }
        else{
            const targetElement=document.getElementById(`${validation.key}`);
            targetElement.classList.add('is-invalid');
            targetElement.focus();
            setSnackbarMessage(validation.message);
            setIsSnackbarVisible(true);  
            setTimeout(()=>{
                setIsSnackbarVisible(false)
            },3000)
            

        }
    }
    return(
        <>
            <Header customizedHeader={1} />
             <section id="common_author_area" class="section_padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="common_author_boxed">
                                <div class="common_author_heading">
                                    <h3>Create new  account</h3>
                                </div>
                                <div class="common_author_form">
                                    <form action="#" id="main_author_form">
                                        <div className="form-floating mb-2">
                                            <input type="text" name="firstName" value={sendForm.firstName} className="form-control" id="firstName" placeholder="First Name" onChange={handleChange} />
                                            <label for="firstName" className="custom-input-font-style">First Name</label>
                                        </div>
                                        <div className="form-floating mb-2">
                                            <input type="text" name="lastName" value={sendForm.lastName} className="form-control" id="lastName" placeholder="Last Name" onChange={handleChange} />
                                            <label for="lastName" className="custom-input-font-style">Last Name</label>
                                        </div>
                                        <div className="form-floating mb-2">
                                            <input type="text" name="email" value={sendForm.email} className="form-control" id="email" placeholder="Email" onChange={handleChange} />
                                            <label for="email" className="custom-input-font-style">Email (Optional)</label>
                                        </div>
                                        <div className="form-floating mb-2">
                                            <input type="text" name="mobileNumber" value={sendForm.mobileNumber} className="form-control" id="mobileNumber" placeholder="Mobile Number" onChange={handleChange} />
                                            <label for="mobileNumber" className="custom-input-font-style">Mobile number</label>
                                        </div>
                                        
                                        <div className="form-floating mb-2">
                                            <input type="password" name="password" value={sendForm.password} className="form-control" id="password" placeholder="Password" onChange={handleChange} />
                                            <label for="password" className="custom-input-font-style">Password</label>
                                        </div>
                                        <div className="form-floating mb-2">
                                            <input type="text" name="confirmPassword" value={sendForm.confirmPassword} className="form-control" id="confirmPassword" placeholder="Confirm Password" onChange={handleChange} />
                                            <label for="confirmPassword" className="custom-input-font-style">Confirm Password</label>
                                        </div>
                                        <div class="common_form_submit">
                                            <button class="btn btn_theme btn_md" disabled={showBtnLoder} onClick={handleSignUp}>
                                            {showBtnLoder ?  <img src={FadeLineLoader} className='btn-loader' alt='loader' /> :<span>SignUp</span>   }
                                            </button>
                                        </div>
                                        <div class="have_acount_area other_author_option">
                                            <div class="line_or">
                                                <span>or</span>
                                            </div>
                                            
                                            <p>Already have an account? <Link to={url.login}>Login now</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="snackbar" className={isSnackbarVisible ? "show" : ""}> {snackbarMessage} </div>
            </section>
        </>
    )
}
export default SignUp;