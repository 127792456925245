import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
 import '../CustomCSS/TopDealsAndDestinations.css'; 

const TopDealsAndDiscounts = () => {
    const options = {
        loop: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        autoplayTimeout: 4000, 
        smartSpeed: 1000,
        margin: 30,
        nav: true,
        navText: [
            "<i class='fas fa-arrow-left'></i>", 
            "<i class='fas fa-arrow-right'></i>"
        ],
        responsive: {
            0: { items: 1 },
            768: { items: 2 },
            992: { items: 2 },
            1399: { items: 2 },
            1400: { items: 3 }
        }
    };

    return (
        <section id="top_details_area" className="section_padding_top">
            <div className="container">
                {/* Section Heading */}
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="section_heading_left">
                            <h2>Top Deals and Discounts</h2>
                            <p>Nostrud aliqua ipsum dolore velit labore nulla fugiat.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3">
                        <div className="top_details_four_item-c">
                            <img src="assets/img/common/offer_banner.png" alt="" />
                            <div className="top_details_posation-c">
                                <p>Get winter deal</p>
                                <h3>Book your ticket to enjoy</h3>
                                <a href="#!" className="btn btn_theme btn_md">Book now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <OwlCarousel className="top_details_four_slider owl-theme" {...options}>
                            <div className="common_card_four">
                                <div className="top_details_four_item-c">
                                    <img src="assets/img/tour/popular-2.png" alt="" />
                                    <div className="top_details_posation-c">
                                        <p>Get winter deal</p>
                                        <h3>Book your ticket to enjoy</h3>
                                        <a href="#!" className="btn btn_theme btn_md">Book now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="common_card_four">
                                <div className="top_details_four_item-c">
                                    <img src="assets/img/common/offer_banner.png" alt="" />
                                    <div className="top_details_posation-c">
                                        <p>Get winter deal</p>
                                        <h3>Book your ticket to enjoy</h3>
                                        <a href="#!" className="btn btn_theme btn_md">Book now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="common_card_four">
                                <div className="top_details_four_item-c">
                                    <img src="assets/img/common/offer_banner.png" alt="" />
                                    <div className="top_details_posation-c">
                                        <p>Get winter deal</p>
                                        <h3>Book your ticket to enjoy</h3>
                                        <a href="#!" className="btn btn_theme btn_md">Book now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="common_card_four">
                                <div className="top_details_four_item-c">
                                    <img src="assets/img/common/offer_banner.png" alt="" />
                                    <div className="top_details_posation-c">
                                        <p>Get winter deal</p>
                                        <h3>Book your ticket to enjoy</h3>
                                        <a href="#!" className="btn btn_theme btn_md">Book now</a>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopDealsAndDiscounts;

