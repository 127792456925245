import React, { useEffect, useState } from 'react';
import { FaFacebook, FaInstagram, FaUser, FaMoneyCheck, FaWallet } from 'react-icons/fa';
import { TbLogout2 } from "react-icons/tb";
import { BiLogIn } from "react-icons/bi";
import { IoCreate } from "react-icons/io5";
import { Navbar, Nav, Container, NavDropdown, Modal, Button } from 'react-bootstrap';
import '../CustomCSS/Header.css'; 
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CommunicationHub from '../Common/CommunicationHub';
import Common from '../Common/Common';
import url from '../Common/url';
import apiService from '../Common/ApiFactory/apiService'

const Header = ({ customizedHeader = 0, isDashboard = 0 ,isFromSearchFlight=0}) => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Home');
  const [isLogin, setIsLogin] = useState(false);
  const [isShowBtnLoader, setIsShowBtnLoader] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const location = useLocation();

  const menuList = [
    { menuName: 'Home', path: '/', isParent: 0 },
    { menuName: 'Tour Packages', path: '/tour-list', isParent: 0 },
    { menuName: 'Flight Ticket', path: '/search-flight', isParent: 0 },
    { menuName: 'About Us', path: '/about-us', isParent: 0 },
    { menuName: 'Contact Us', path: '/contact-us', isParent: 0 },
    {
      menuName: 'Policies', path: '', isParent: 1,
      childMenu: [
        { menuName: 'Privacy Policy', path: '/privacy-policy' },
        { menuName: 'Refund & Cancellation Policy', path: '/refund-cancellation-policy' },
        { menuName: 'Terms & Conditions', path: '/term-conditions-policy' }
      ]
    },
  ];

  const handleClick = () => {
    navigate(url.mytrips);
  };
  const redirectToLogin = () => {
    navigate(url.login);
  };
  const redirectToSignUp = () => {
    navigate(url.signup);
  };
  const handleLogout = async() => {
    const obj ={
      "userID": Common.getUserLoginDetails().userID,
      "sessionToken": Common.getUserLoginDetails().sessionToken
    }
    setIsShowBtnLoader(true)
    try{
        await apiService.post('Authentication/UserLogout',obj);
    }
    catch(error){
      console.error('Error While Logout Err :',error)
    }
    localStorage.setItem('isLoggedIn',false);
    localStorage.removeItem('userLoginData');
    localStorage.removeItem('data');
    setIsShowBtnLoader(false);
    setShowLogoutModal(false);
    navigate(url.login);
    
  };
  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };

  useEffect(() => {
    setIsLogin(Common.isUserAuthenticated());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    if (!isDashboard) {
      const activeItem = menuList.filter(item => item.path === location.pathname);
      const activeMenuName = activeItem.length > 0 ? activeItem[0].menuName : "";
      setActiveMenu(activeMenuName);
    } else {
      setActiveMenu("");
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, menuList]);

  return (
    <header className={`${isFromSearchFlight === 1 ? "position-rel":"main_header_arae"}`}>
      {/* Top Bar */}
      <div className={`topbar-area ${customizedHeader ? 'cus-bg' : ''}`}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <ul className="topbar-list">
                <li>
                  <Link to={CommunicationHub.faceBookPageUrl} target='_blank'><FaFacebook /></Link>
                  <Link to={CommunicationHub.instagramUrl} target='_blank' className='cus-padding-left-10'><FaInstagram /></Link>
                </li>
                <li><Link to={`tel:${CommunicationHub.landLineNo}`}><span>{CommunicationHub.landLineNo}</span></Link></li>
                <li><Link to={`mailto:${CommunicationHub.contactMail}`}><span>{CommunicationHub.contactMail}</span></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Navbar Area */}
      <div className={`navbar-area ${isSticky ? 'is-sticky' : ''} ${customizedHeader ? 'cus-bg' : ''}`}>
        <Navbar expand="lg" sticky="top">
          <Container>
            <Navbar.Brand >
              <Link to='/'><span className='cus-text-white'>{Common.brandName}</span></Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {menuList && menuList.map((val) => {
                  return (
                    <>
                      {val.isParent === 0 ?
                        <Link to={val.path} className={`cus-nav-link cus-menu-a ${activeMenu === val.menuName ? 'cus-active' : ''}`}>{val.menuName}</Link>
                        :
                        <NavDropdown title={val.menuName} id="basic-nav-dropdown" className='parent-menu-title'>
                          {val.childMenu && val.childMenu.map((child) => {
                            return (
                              <NavDropdown.Item as={Link} to={child.path}><span>{child.menuName}</span></NavDropdown.Item>
                            )
                          })}
                        </NavDropdown>
                      }
                    </>
                  )
                })}
              </Nav>
              <Nav>
                <NavDropdown title={<FaUser />} id="basic-nav-dropdown" className='parent-menu-title cm'>
                  {isLogin ?
                    <>
                      <NavDropdown.Item as={Link} to=""><FaUser /><span className='ms-1'>My Profile</span></NavDropdown.Item>
                      <NavDropdown.Item onClick={handleClick}><FaMoneyCheck /><span className='ms-1'>My Trips</span></NavDropdown.Item>
                      <NavDropdown.Item as={Link} to=""><FaWallet /><span className='ms-1'>Wallet</span></NavDropdown.Item>
                      <NavDropdown.Item onClick={()=>{setShowLogoutModal(true)}} className='ms-1'><TbLogout2 /><span>Logout</span></NavDropdown.Item>
                    </>
                    :
                    <>
                      <NavDropdown.Item onClick={handleClick}><FaMoneyCheck /><span className='ms-1'>My Trips</span></NavDropdown.Item>
                      <NavDropdown.Item onClick={redirectToLogin}><BiLogIn /><span className='ms-1'>Login</span></NavDropdown.Item>
                      <NavDropdown.Item onClick={redirectToSignUp}><IoCreate /><span className='ms-1'>Sign Up</span></NavDropdown.Item>
                    </>
                  }
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      {/* Logout Modal */}
      <Modal show={showLogoutModal} onHide={closeLogoutModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Logout Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to logout?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeLogoutModal}>
            Cancel
          </Button>
          {isShowBtnLoader ? <span class="spinner-border text-primary" role="status"></span>
          : <Button variant="primary" onClick={handleLogout}>Logout</Button>}
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default Header;
