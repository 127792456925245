import React, { useEffect, useRef, useState } from "react";
import Header from "../../Header";
import './index.css';
import apiService from '../../../Common/ApiFactory/apiService';
import url from '../../../Common/url';
import SpinnerLoader from "../../Loader/SpinnerLoader";
import { useNavigate } from "react-router-dom";
// import 'nouislider/distribute/nouislider.css';
const Tour = ()=>{
    const navigate =useNavigate();
    const categoryDropdownRef = useRef(null);
    const locationDropdownRef = useRef(null);
    const [showLoader,setShowLoader]=useState(false);
    const [categoryList,setCategoryList]=useState([]);
    const [locationList,setLocationList]=useState([]);
    const[showCategoryDrpdown,setShowCategoryDrpdown]=useState(false);
    const[showLocationDrpdown,setShowLocationDrpdown]=useState(false);
    const[selectedCategory,setSelectedCategory]=useState(0);
    const[selectedCategoryName,setSelectedCategoryName]=useState('');
    const[searchQuery,setSearchQuery]=useState('');
    const[selectedLocation,setSelectedLocation]=useState('');
    const[isApiCall,setIsApiCall]=useState(0);
    const [tourList,setTourList]=useState([]);
    // const [tourList,setTourList]=useState([
    //     {
    //         tourName:'Manali',
    //         country:'india',
    //         rating:'4.6/5',
    //         reviewCount:"10 reviewes",
    //         priceIcon:'&#8377;',
    //         price:'5999',
    //         pyablePrice:'2999',
    //         details:'Price starts from',
    //         imagePath:'assets/img/tab-img/hotel1.png',
    //         locationIcon:'fas fa-map-marker-alt',
    //         isDiscounted:true,
    //         discountPecentage:'50%'
    //     },
    //     {
    //         tourName:'Shimla',
    //         country:'india',
    //         rating:'4.6/5',
    //         reviewCount:"10 reviewes",
    //         priceIcon:'&#8377;',
    //         price:'5999',
    //         pyablePrice:'2999',
    //         details:'Price starts from',
    //         imagePath:'assets/img/tab-img/hotel1.png',
    //         locationIcon:'fas fa-map-marker-alt',
    //         isDiscounted:false,
    //         discountPecentage:''
    //     },
    // ]);
    const getCategoryList = async () => {
        try{
            const {status,responseValue}= await apiService.get(`Admin/GetCategoryList`);
            if (status === 1)setCategoryList(responseValue);
            console.log('responseValue',responseValue);
        }
        catch(error){
            console.error('Error Get Category List->',error);
        } 
    };
    const handleSelectCategory=(value)=>{
        setSelectedCategory(value.id);
        setSelectedCategoryName(value.category_name);
        setShowCategoryDrpdown(false);
    };
    const getLocationList=async()=>{
        try{
            const {status,responseValue}= await apiService.get(`SearchEngine/SearchLocation?KeyWord=${searchQuery}`);
            if (status === 1){
                setLocationList(responseValue);
                setShowLocationDrpdown(true);
            }
            else{
                setShowLocationDrpdown(false);
            }
           
        }
        catch(error){
            console.error('Error Get Location List->',error);
        } 
    }
    const handleSearch=(e)=>{
        const value = e.target.value;
        setSearchQuery(value);
    };
    const handleSelectLocation =(param)=>{
        document.getElementById('searchLocation').value=param;
        setShowLocationDrpdown(false);
    };
    const getAllTour= async ()=>{
        setShowLoader(true);
        const KeyWord="";
        const Category=-1;

        try{
            const{responseValue}= await apiService.get(`SearchEngine/SearchTour?KeyWord=${KeyWord}&categoryID=${Category}`);
            setShowLoader(false);
            setTourList(responseValue);
            console.log('responseValue tour',responseValue);
        }
        catch(error){
            setShowLoader(false);
            console.error('Error in Search Tour->',error)
        }
    }
    const handleSearchTour= async(fromFinder,categoryID)=>{
        console.log('fromFinder',fromFinder)
        const txtLocation = document.getElementById('searchLocation').value;
        const selectedCategoryID= fromFinder === 1 ? categoryID : selectedCategory
        setShowLoader(true);
        try{
            const{responseValue,status}= await apiService.get(`SearchEngine/SearchTour?KeyWord=${txtLocation}&categoryID=${selectedCategoryID}`);
            setShowLoader(false);
            setTourList(responseValue);
            console.log('responseValue tour',responseValue);
        }
        catch(error){
            setShowLoader(false);
            console.error('Error in Search Tour->',error)
        }
    };
    const handleViewPackage=(param)=>{
        const encodedString=btoa(param.product_id);
        const urlParam=`product-key=${encodedString}`;
        const searchUrl=`${url.viewTourDetails}?${urlParam}`;
        navigate(searchUrl);
    };
    const handleCheckRequestUrl=()=>{
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        console.log('param',param)
        if(param.size){
            const key = param.get("search-tour");
            const key2 = param.get("category");
            const key3 = param.get("categoryName");
            console.log('key2',key2)
            if(key || key2){
                try{
                    const decodedString=atob(key);
                    document.getElementById('searchLocation').value=decodedString;
                    setSelectedCategory(key2);
                    setSelectedCategoryName(key3);
                    handleSearchTour(1,key2)
                    
                }
                catch(error){
                    console.error('window.URL Package-details',error);
                    getAllTour();
                }
            }
            else{
                getAllTour();
            } 
        }
        else{
            getAllTour();
        }
        
    };
    const handleShorting=()=>{
        const val=document.getElementById('ddlShorting').value;
        const tempArr=[...tourList];
        if(val === 0){
            return
        }
        else if(val === "0_1"){
            tempArr.sort((a,b)=>a.discounted_price-b.discounted_price);
        }
        else if(val === "1_0"){
            tempArr.sort((a,b)=>b.discounted_price-a.discounted_price);
        }
        
        setTourList(tempArr)
    }
useEffect(()=>{
    handleCheckRequestUrl()
    getCategoryList();
},[])
useEffect(()=>{
    if(isApiCall){
        let timeout= setTimeout(()=>{
            console.log('dddd',searchQuery);
            getLocationList();
        },500);
        return ()=>clearTimeout(timeout);
    }
    setIsApiCall(1);
    

    
},[searchQuery])
useEffect(() => {
    const handleClickOutside = (event) => {
        if (categoryDropdownRef.current && !categoryDropdownRef.current.contains(event.target)) {
            setShowCategoryDrpdown(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [categoryDropdownRef]);
useEffect(() => {
    const handleClickOutside = (event) => {
        if (locationDropdownRef.current && !locationDropdownRef.current.contains(event.target)) {
            setShowLocationDrpdown(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [locationDropdownRef]);
return(
    <>
        <Header customizedHeader={1} isDashboard={0}/>
        <section id="explore_area" className="section_padding">
        <div className="container mt-4">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section_heading_center">
                        <h2>Tour Packages</h2>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" >
                    <div ref={locationDropdownRef}>
                        <input type="text" className="form-control" id="searchLocation" placeholder="where are you going" onChange={handleSearch} />
                        {showLocationDrpdown ?
                            <div class="dropdown">
                                <div class="dropdown-content">
                                    {locationList && locationList.map((list)=>{
                                        return(
                                            <>
                                            <span onClick={()=>{handleSelectLocation(list.location)}}>{list.location}</span>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        :''}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 cus-mt" title="Choose Category">
                    <div ref={categoryDropdownRef}>
                        <button className="form-control t-left"  onClick={()=>{setShowCategoryDrpdown(!showCategoryDrpdown)}}>{selectedCategoryName || 'Choose Category'}</button>
                        {showCategoryDrpdown ?
                            <div class="dropdown">
                                <div class="dropdown-content">
                                    {categoryList && categoryList.map((list)=>{
                                        return(
                                            <>
                                            <span onClick={()=>{handleSelectCategory(list)}}>{list.category_name}</span>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        :''}
                    </div>
                </div>
                
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 cus-mt">
                        <button className="btn btn_theme btn_md" onClick={()=>{handleSearchTour(0)}} >Search</button>
                    {/* <button className="form-control bg-color-main text-white" placeholder="where are you going" onClick={handleSearchTour}>Search</button> */}
                </div>
            </div>
            <div className="row ps-3 cus-filter">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 left_side_search_boxed">
                    <div className="left_side_search_heading">
                        <h5>Sort by </h5>
                    </div>
                    <div className="filter-price">
                        <select className="form-control" id="ddlShorting">
                            <option value="0">Choose</option>
                            <option value="1_0">Price : High to Low</option>
                            <option value="0_1">Price : Low to High</option>
                        </select>
                    </div>
                    <button className="apply" type="button" onClick={handleShorting}>Apply</button>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="mt-3 mb-3">
                         {showLoader ? <SpinnerLoader /> : ''}
                    </div>
                    <div className="row">
                        {tourList && tourList.map((product,ind)=>{
                            return(
                                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="theme_common_box_two img_hover card c-pointer" key={ind+1}>
                                    <div className="theme_two_box_img card-img">
                                        <img src={product.media_url} alt="img" />
                                            <p><i className="fas fa-map-marker-alt"></i>{product.location}</p>
                                        </div>
                                    <div className="theme_two_box_content card-content" >
                                        <h4>{product.product_name}</h4>
                                        <p><span className="review_rating">4.8/5 Excellent</span> <span className="review_count">(1214 reviews)</span></p>
                                     
                                            <div className="price-section">
                                                <div className="discounted-price me-2">₹{product.discounted_price}</div>
                                                <div className="base-price">₹{product.base_price}</div>
                                                <span className="discount-percentage">{product.discount_percentage} off</span>
                                            </div>
                                        
                                    </div>
                                    <div className="cruise_content_bottom_right jutify-content-center c-card-button">
                                            <button className="btn btn_theme btn_md col-12" onClick={()=>{handleViewPackage(product)}}>View</button>
                                    </div>
                                    </div>
                                </div>
                            )
                        })}
                   
                       
                        {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel2.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Indonesia</p>
                                    <div className="discount_tab">
                                        <span>50%</span>
                                    </div>

                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Hotel paradise international</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel3.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Kualalampur</p>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Hotel kualalampur</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel4.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Mariana island</p>
                                    <div className="discount_tab">
                                        <span>50%</span>
                                    </div>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Hotel deluxe</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel5.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Kathmundu, Nepal</p>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Hotel rajavumi</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel6.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Beach view</p>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Thailand grand suit</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel7.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Long island</p>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Zefi resort and spa</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel8.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Philippine</p>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Manila international resort</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel1.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>New beach, Thailand</p>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Kantua hotel, Thailand</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel1.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>New beach, Thailand</p>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Kantua hotel, Thailand</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel2.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Indonesia</p>
                                    <div className="discount_tab">
                                        <span>50%</span>
                                    </div>

                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Hotel paradise international</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="theme_common_box_two img_hover">
                                <div className="theme_two_box_img">
                                    <a href="tour-details.html"><img src="assets/img/tab-img/hotel3.png" alt="img" /></a>
                                    <p><i className="fas fa-map-marker-alt"></i>Kualalampur</p>
                                </div>
                                <div className="theme_two_box_content">
                                    <h4><a href="tour-details.html">Hotel kualalampur</a></h4>
                                    <p><span className="review_rating">4.8/5 Excellent</span> <span
                                            className="review_count">(1214
                                            reviewes)</span></p>
                                    <h3>$99.00 <span>Price starts from</span></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="pagination_area">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    
                </div>
                
            </div>
        </div>
        </section>
    </>
)
}
export default Tour;

 {/* <div class="search-container">
                        <div>
                            <input type="text" placeholder="Tours" class="search-input" />
                            <div class="dropdown">
                                <div class="dropdown-content">
                                    <a href="#">Dubai</a>
                                    <a href="#">Downtown Dubai</a>
                                    <a href="#">Dubai International Airport</a>
                                    <a href="#">Dubai Marina</a>
                                    <a href="#">Bur Dubai</a>
                                </div>
                            </div>
                        </div>
                        <div class="date-input-container">
                            <input type="text" onfocus="(this.type='date')" placeholder="Thu 26 Sept" class="date-input" />
                            <input type="text" onfocus="(this.type='date')" placeholder="Fri 27 Sept" class="date-input" />
                        </div>
                        <div class="select-container">
                            <select class="custom-select">
                                <option>2 adults</option>
                                <option>1 adult</option>
                                <option>3 adults</option>
                                <option>4 adults</option>
                            </select>
                            <select class="custom-select">
                                <option>0 children</option>
                                <option>1 child</option>
                                <option>2 children</option>
                            </select>
                            <select class="custom-select">
                                <option>1 room</option>
                                <option>2 rooms</option>
                                <option>3 rooms</option>
                            </select>
                        </div>
                        <button class="search-button">Search</button>
                    </div> */}