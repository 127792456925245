import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import expIcon from '../assets/img/Icon/experianceIcon.png';
import happyCustomerIcon from '../assets/img/Icon/happyCustomer.png';
import destinationIcon from '../assets/img/Icon/destination.png';
import bookingIcon from '../assets/img/Icon/booking.png';

const CounterArea = () => {
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [counts, setCounts] = useState({
    customer: 0,
    yearExp: 0,
    destinations: 0,
    bookings: 0,
  });

  useEffect(() => {
    if (inView) {
      const startCount = (target, end, duration) => {
        let start = 0;
        const stepTime = Math.abs(Math.floor(duration / end));
        const timer = setInterval(() => {
          start += 1;
          setCounts((prevCounts) => ({
            ...prevCounts,
            [target]: start,
          }));
          if (start === end) clearInterval(timer);
        }, stepTime);
      };

      startCount('customer', 200, 2000);
      startCount('yearExp', 10, 2000);
      startCount('destinations', 100, 2000);
      startCount('bookings', 500, 2000);
    }
  }, [inView, entry]);

  return (
    <section id="counter_area" className="section_padding_bottom" ref={ref} style={{marginTop:'50px',marginBottom:'50px'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 ">
            <div className="counter_area_wrapper">
              <div className="row">
                <div className="col-lg-3 col-md-6 clo-sm-12 col-12">
                  <div className="counter_item">
                    <img className='counter-icon' src={expIcon} alt="icon" />
                    <h3 className="counter">{counts.yearExp}+</h3>
                    <h6>Years of Experience</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 clo-sm-12 col-12">
                  <div className="counter_item">
                    <img className='counter-icon' src={happyCustomerIcon} alt="icon" />
                    <h3 className="counter">{counts.customer}+</h3>
                    <h6>Happy Customers</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 clo-sm-12 col-12">
                  <div className="counter_item">
                    <img className='counter-icon' src={destinationIcon} alt="icon" />
                    <h3 className="counter">{counts.destinations}+</h3>
                    <h6>Destinations</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 clo-sm-12 col-12">
                  <div className="counter_item">
                    <img className='counter-icon' src={bookingIcon} alt="icon" />
                    <h3 className="counter">{counts.bookings}+</h3>
                    <h6>Booking</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterArea;
