import React, { useEffect, useRef, useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import './style.css';
import CommunicationHub from "../../../Common/CommunicationHub";
const Success=()=>{
    document.title="Payment - Success";
    const[isDownload,setIsDownload]=useState(false);
    const invoiceRef = useRef(null);
    const[ticketDetails,setTicketDetails]=useState({});
    const[paymentDetails,setPaymentDetails]=useState({});
    const handleCheckRequestUrl= async()=>{
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        if(param.size){
            const key = param.get("data");
            const key2 = param.get("response");
            if(key || key2){
                try{
                    const decodedStringData=atob(key);
                    const decodedStringResponse=atob(key2);
                    try{
                        const ticketDetails=JSON.parse(decodedStringData);
                        const paymentDetails=JSON.parse(decodedStringResponse);
                        console.log('responseData',paymentDetails[0]);
                        console.log('ticketDetails',ticketDetails);
                        setTicketDetails(ticketDetails);
                        setPaymentDetails(paymentDetails[0]);
                    }
                    catch(error){
                        console.error('Error While Parsing URL Req Err->',error);
                        
                    }                    
                }
                catch(error){
                    console.error('Error While Decoding Url Req Err->',error);
                    
                }
            }
            
        }
        
        
    };
    const handleDownloadPDF = () => {
        const doc = new jsPDF("p", "pt", "a4");
        const invoice = invoiceRef.current;
      
        html2canvas(invoice, { scale: 2 }) // Adjust scale as needed (2 -> 1.5)
          .then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg", 0.85); // Using JPEG and setting quality to 85%
            const imgProps = doc.getImageProperties(imgData);
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      
            doc.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight, '', 'FAST'); // Use 'FAST' compression
            // doc.save("invoice.pdf");
            doc.save(`${paymentDetails.passengerName}_ticket.pdf`);
          })
          .catch((err) => {
            console.error("Error generating PDF: ", err);
          });
      };
    useEffect(()=>{
        handleCheckRequestUrl();
    },[])
    return(
        <>              


            {/*  */}
           <div className="container">
                <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="message-box _success">
                                <i className="fa fa-check-circle mb-3" aria-hidden="true"></i>
                                <h3> Ticket booked! </h3>
                                <p className="mt-1">{paymentDetails.displayName} Thanks for choosing us!</p>    
                                 
                            </div> 
                        </div> 
                </div> 
                <div className="row">
                        <div className="col-12">
                            <div className="tour_details_leftside_wrapper" ref={invoiceRef}>
                                <div className="tour_details_boxed">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12">
                                            <div>
                                                <h3>{CommunicationHub.brandName}</h3>
                                                <p className="mb-0">
                                                201 2nd Floor, Ace Airosa Complex,
                                                </p>
                                                <p className="mb-0">
                                                Oden Cinema, Cantonment Rd, Odeon Colony, Lalbagh, 
                                                </p>
                                                <p className="mb-0">
                                                Lucknow, Uttar Pradesh 226001 
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div class="invoice-info">
                                                {/* <h2>INVOICE</h2> */}
                                                <p>Booking No : <span>{paymentDetails.bookingNo}</span></p>
                                                <p>Date: <span>{paymentDetails.transactionDateTime}</span></p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flight_search_item_wrappper">
                                        <div className="flight_search_items">
                                            <div className="row">
                                                {/* Left */}
                                                <div className="col-xl-6 col-md-6 col-sm-12">
                                                    <div className="multi_city_flight_lists">
                                                        <div className="flight_multis_area_wrapper">
                                                            <div className="flight_policy_refund border-none">
                                                                {ticketDetails.isConnectedFlight === 1 ?
                                                                    <>
                                                                        {ticketDetails.connectionList && ticketDetails.connectionList.length > 0 && ticketDetails.connectionList.map((conn,ind,allConn)=>{
                                                                            return(
                                                                                    <>
                                                                                        {ind === 0 ?
                                                                                            <div className="flight_show_down_wrapper border-none" key={ind+1}>
                                                                                                <div className="flight-shoe_dow_item">
                                                                                                    <div className="airline-details">
                                                                                                        <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                                                        <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                                                        <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                                                                    </div>
                                                                                                    <div className="flight_inner_show_component">
                                                                                                        <div className="flight_det_wrapper">
                                                                                                            <div className="flight_det">
                                                                                                                <div className="code_time">
                                                                                                                    <span className="code"> {ticketDetails.formattedDepartureDate}</span><br />
                                                                                                                    <span className="time font-1rem"> {ticketDetails.departureTime}</span> <br />
                                                                                                                    <span className="time font-sec"> {ticketDetails.departureSectorCode} - {ticketDetails.departureSectorName}</span>
                                                                                                                </div>
                                                                                                                <p className="airport mb-1">
                                                                                                                    {ticketDetails.departureSectorLocation}
                                                                                                                    <br />
                                                                                                                    <span className="font-sec">{ticketDetails.departureTerminal} </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flight_duration">
                                                                                                            <div className="arrow_right"></div>
                                                                                                            <span> {conn.timeDifference ?? "7h 0m"}</span>
                                                                                                        </div>
                                                                                                        <div className="flight_det_wrapper">
                                                                                                            <div className="flight_det">
                                                                                                                <div className="code_time">
                                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                                </div>
                                                                                                                <p className="airport mb-1">
                                                                                                                    {conn.arrivalSectorLocation}
                                                                                                                    <br />
                                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="flight_refund_policy">
                                                                                                    
                                                                                                    <div className="TabPanelInner">
                                                                                                        <h4>Hand - Check Baggage</h4>
                                                                                                        <div className="flight_info_taable mt-1">
                                                                                                            <p><span>{(conn.handbaggageWeight)} KG - {conn.checkInBaggageWeight } KG /</span> person</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        : 
                                                                                            <div className="flight_show_down_wrapper border-none" key={ind+1}>
                                                                                                <div className="flight-shoe_dow_item">
                                                                                                    <div className="airline-details">
                                                                                                        <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                                                        <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                                                        <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                                                                    </div>
                                                                                                    <div className="flight_inner_show_component">
                                                                                                        <div className="flight_det_wrapper">
                                                                                                            <div className="flight_det">
                                                                                                                <div className="code_time">
                                                                                                                    <span className="code"> {allConn[(ind-1)].formattedArrivalDate}</span><br />
                                                                                                                    <span className="time font-1rem"> {allConn[(ind-1)].arrivalTime}</span> <br />
                                                                                                                    <span className="time font-sec"> {allConn[(ind-1)].arrivalSectorCode} - {allConn[(ind-1)].arrivalSectorName}</span>
                                                                                                                </div>
                                                                                                                <p className="airport mb-1">
                                                                                                                    {allConn[(ind-1)].arrivalSectorLocation}
                                                                                                                    <br />
                                                                                                                    <span className="font-sec">{allConn[(ind-1)].arrivalTerminal} </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flight_duration">
                                                                                                            <div className="arrow_right"></div>
                                                                                                            <span> {allConn[(ind-1)].timeDifference}</span>
                                                                                                        </div>
                                                                                                        <div className="flight_det_wrapper">
                                                                                                            <div className="flight_det">
                                                                                                                <div className="code_time">
                                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                                </div>
                                                                                                                <p className="airport mb-1">
                                                                                                                    {ticketDetails.arrivalSectorLocation}
                                                                                                                    <br />
                                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="flight_refund_policy">
                                                                                                    
                                                                                                    <div className="TabPanelInner">
                                                                                                    <h4>Hand - Check Baggage</h4>
                                                                                                        <div className="flight_info_taable mt-1">
                                                                                                            <p><span>{(conn.handbaggageWeight)} KG - {conn.checkInBaggageWeight } KG /</span> person</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>              
                                                                                        }
                                                                                        {ind === (ticketDetails.connectionList.length-1) ?
                                                                                            <div className="flight_show_down_wrapper border-none" key={ind+1}>
                                                                                                <div className="flight-shoe_dow_item">
                                                                                                    <div className="airline-details">
                                                                                                        <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                                                        <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                                                        <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                                                                    </div>
                                                                                                    <div className="flight_inner_show_component">
                                                                                                        <div className="flight_det_wrapper">
                                                                                                            <div className="flight_det">
                                                                                                                <div className="code_time">
                                                                                                                    <span className="code"> {conn.formattedArrivalDate}</span><br />
                                                                                                                    <span className="time font-1rem"> {conn.arrivalTime}</span> <br />
                                                                                                                    <span className="time font-sec"> {conn.arrivalSectorCode} - {conn.arrivalSectorName}</span>
                                                                                                                </div>
                                                                                                                <p className="airport mb-1">
                                                                                                                    {conn.arrivalSectorLocation}
                                                                                                                    <br />
                                                                                                                    <span className="font-sec">{conn.arrivalTerminal} </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flight_duration">
                                                                                                            <div className="arrow_right"></div>
                                                                                                            <span> {conn.timeDifference ?? "3h 9m"}</span>
                                                                                                        </div>
                                                                                                        <div className="flight_det_wrapper">
                                                                                                            <div className="flight_det">
                                                                                                                <div className="code_time">
                                                                                                                    <span className="code"> {ticketDetails.formattedArrivalDate}</span><br />
                                                                                                                    <span className="time font-1rem"> {ticketDetails.arrivalTime}</span> <br />
                                                                                                                    <span className="time font-sec"> {ticketDetails.arrivalSectorCode} - {ticketDetails.arrivalSectorName}</span>
                                                                                                                </div>
                                                                                                                <p className="airport mb-1">
                                                                                                                    {ticketDetails.arrivalSectorLocation}
                                                                                                                    <br />
                                                                                                                    <span className="font-sec">{ticketDetails.arrivalTerminal} </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="flight_refund_policy">
                                                                                                    
                                                                                                    <div className="TabPanelInner">
                                                                                                    <h4>Hand - Check Baggage</h4>
                                                                                                        <div className="flight_info_taable mt-1">
                                                                                                            <p><span>{(conn.handbaggageWeight)} KG - {conn.checkInBaggageWeight } KG /</span> person</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> 
                                                                                        :""}
                                                                                    </>
                                                                            );
                                                                        })}
                                                                    </>
                                                                :
                                                                        <div className="flight_show_down_wrapper border-none" key={1}>
                                                                            <div className="flight-shoe_dow_item">
                                                                                <div className="airline-details">
                                                                                    <div className="img"><img src={ticketDetails.airlineLogo} alt="img" /></div>
                                                                                    <span className="airlineName fw-500">{ticketDetails.airline_name}</span>
                                                                                    <span className="flightNumber"> {ticketDetails.airline_no}</span>
                                                                                </div>
                                                                                <div className="flight_inner_show_component">
                                                                                    <div className="flight_det_wrapper">
                                                                                        <div className="flight_det">
                                                                                            <div className="code_time">
                                                                                                <span className="code"> {ticketDetails.departureSectorCode}</span>
                                                                                                <span className="time"> {ticketDetails.departureTime}</span>
                                                                                            </div>
                                                                                            <p className="airport">{ticketDetails.departureTerminal} {ticketDetails.departureSectorLocation}</p>
                                                                                            <p className="date"> {ticketDetails.formattedDepartureDate}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flight_duration">
                                                                                        <div className="arrow_right"></div>
                                                                                        <span> {ticketDetails.timeDifference}</span>
                                                                                    </div>
                                                                                    <div className="flight_det_wrapper">
                                                                                        <div className="flight_det">
                                                                                            <div className="code_time">
                                                                                                <span className="code"> {ticketDetails.arrivalSectorCode}</span>
                                                                                                <span className="time"> {ticketDetails.arrivalTime}</span>
                                                                                            </div>
                                                                                            <p className="airport">{ticketDetails.arrivalTerminal} {ticketDetails.arrivalSectorLocation}</p>
                                                                                            <p className="date"> {ticketDetails.formattedArrivalDate}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flight_refund_policy">
                                                                                
                                                                                <div className="TabPanelInner">
                                                                                <h4>Hand - Check Baggage</h4>
                                                                                    <div className="flight_info_taable mt-1">
                                                                                        <p><span>{(ticketDetails.handbaggageWeight)} KG - {ticketDetails.checkInBaggageWeight } KG /</span> person</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    {/* RIght */}
                                                <div className="col-xl-6 col-md-6 col-sm-12">
                                                    <div className="multi_city_flight_lists">
                                                        <div className="flight_multis_area_wrapper">
                                                            <div className="flight_policy_refund border-none pt-4">
                                                            <span className="fw-500">Details</span>
                                                                <div className="flight_show_down_wrapper border-none" key={1}>
                                                                    
                                                                    <div className="flight-shoe_dow_item">
                                                                        <div className="flight_inner_show_component">
                                                                            <div className="flight_det_wrapper">
                                                                                <div className="flight_det">
                                                                                    <div className="code_time">
                                                                                        <p className="mb-1">Passenger Name</p>
                                                                                        <span className="time">{paymentDetails.passengerName}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flight-shoe_dow_item">
                                                                        <div className="flight_inner_show_component">
                                                                            <div className="flight_det_wrapper">
                                                                                <div className="flight_det">
                                                                                    <div className="code_time">
                                                                                        <p className="mb-1">Mobile Number</p>
                                                                                        <span className="time">{paymentDetails.mobileNo}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flight-shoe_dow_item">
                                                                        <div className="flight_inner_show_component">
                                                                            <div className="flight_det_wrapper">
                                                                                <div className="flight_det">
                                                                                    <div className="code_time">
                                                                                    <p className="mb-1">Booking Status</p>
                                                                                    <span className="time">Confirmed</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="multi_city_flight_lists">
                                                        <div className="flight_multis_area_wrapper">
                                                            <div className="flight_policy_refund border-none pt-4">
                                                            {/* <span className="fw-500">Passenger Details</span> */}
                                                                <div className="flight_show_down_wrapper border-none" key={1}>
                                                                    
                                                                    <div className="flight-shoe_dow_item">
                                                                        <div className="flight_inner_show_component">
                                                                            <div className="flight_det_wrapper">
                                                                                <div className="flight_det">
                                                                                    <div className="code_time">
                                                                                        <p className="mb-1">Total Fare</p>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                                                                                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
                                                                                            </svg>
                                                                                        <span className="time">{ticketDetails.normalTicketFare}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flight-shoe_dow_item">
                                                                        <div className="flight_inner_show_component">
                                                                            <div className="flight_det_wrapper">
                                                                                <div className="flight_det">
                                                                                    <div className="code_time">
                                                                                        <p className="mb-1">Paid Amount</p>
                                                                                        <span className="time">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
                                                                                                <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
                                                                                            </svg>
                                                                                            {paymentDetails.paymentAmount}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flight-shoe_dow_item">
                                                                        <div className="flight_inner_show_component">
                                                                            <div className="flight_det_wrapper">
                                                                                <div className="flight_det">
                                                                                    <div className="code_time">
                                                                                        <p className="mb-1">Payment Status</p>
                                                                                        <span className="time">Complete</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div className="text-center mt-3 mb-3">
                            <button type="button" className="btn btn-outline-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                    </svg>
                                     <span className="ms-2 mt-1" onClick={handleDownloadPDF}>Download</span>
                            </button>
                       </div>
                </div>
           </div>
           
        </>
    )
}
export default Success;