import { Link } from 'react-router-dom';
import CommunicationHub from '../../../Common/CommunicationHub';
import './style.css';
const FailedPayment=()=>{
    document.title="Payment - Failed";
    return(
        <>
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-md-5">
                <div className="message-box _success _failed">
                     <i className="fa fa-times-circle mb-2" aria-hidden="true"></i>
                    <h2> Your payment failed </h2>
             <p>  Try again later </p> 
             <div className='col-md-12 mt-3'>
                                    <p className="reftitle">Transaction Id : <span className="f12">hgdhdhjhjhhghg</span></p>
                                    <p className="reftitle">Booking No : <span className="f12">hgdhdh</span></p>
                                    <p className="reftitle">Booking Date : <span className="f12">hgdhdh</span></p>
                                    
                                </div>  
                                <p className='mt-2 mb-0'>If payment is deducted without confirmation, please reach out to us.
                                <br /><Link to={`tel:${CommunicationHub.landLineNo}`}>{CommunicationHub.landLineNo}</Link></p>
            </div> 
            
        </div> 
    </div> 
</div> 
        </>
    )
};
export default FailedPayment; 