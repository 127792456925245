import React, { useState } from "react";
import Header from "../../Header";
import './index.css'
import { Link } from "react-router-dom";
import Newsletter from "../../Newsletter";
import Footer from "../../Footer";
import ScrollToTop from "../../scrollToTop";
import CommunicationHub from "../../../Common/CommunicationHub";
import UseResetScroll from "../../../Common/AutoScroll/UseResetScroll";
const ContactUs = () => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", form);
  };
  UseResetScroll();
  return (
    <>
     <Header customizedHeader={1}/>
      <section id="contact_main_area" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section_heading_center">
                <h2>Contact with us</h2>
              </div>
            </div>
          </div>
          <div className="contact_main_form_area_two">
            <div className="row">
              <div className="col-lg-8">
                <div className="contact_left_top_heading">
                  <h2>
                    Do you have any query? Contact with us to get any support.
                  </h2>
                  <h3>Leave us a message</h3>
                  <p>
                    Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci
                    phaedrum. There are many variations of passages of Lorem
                    Ipsum available but the majority.
                  </p>
                </div>
                <div className="contact_form_two">
                  <form id="contact_form_content" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="First name*"
                            name="firstName"
                            value={form.firstName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Last name*"
                            name="lastName"
                            value={form.lastName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control bg_input"
                            placeholder="Email address (Optional)"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Mobile number*"
                            name="mobile"
                            value={form.mobile}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            className="form-control bg_input"
                            rows="5"
                            placeholder="Message"
                            name="message"
                            value={form.message}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn cus-button btn_md"
                          >
                            Send message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contact_two_left_wrapper">
                  <h3>Contact details</h3>
                  <div className="contact_details_wrapper">
                    <div className="contact_details_item">
                      <h4 className="cus-h4">Help line</h4>
                      <h3>
                        <Link className="cus-subheading" to={`tel:${CommunicationHub.landLineNo}`}>{CommunicationHub.landLineNo}</Link>
                      </h3>
                    </div>
                    <div className="contact_details_item mt-1">
                      <h4 className="cus-h4">Support mail</h4>
                      <h3 >
                        <Link className="cus-subheading" to={`mailto:${CommunicationHub.supportMail}`}>
                        {CommunicationHub.supportMail}
                        </Link>
                      </h3>
                    </div>
                    <div className="contact_details_item mt-1">
                      <h4 className="cus-h4">Contact hour</h4>
                      <h3 className="cus-subheading">Mon-Sun : 24 hours</h3>
                    </div>
                    <div className="contact_map_area mt-1">
                    <iframe class="googlemap" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14239.594686279943!2d80.9363514!3d26.843175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf66936bcfa5080e2!2sTRAVEL%20RIGHT!5e0!3m2!1sen!2sin!4v1659461396565!5m2!1sen!2sin" width="600" height="450" style={{border:"0px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
      <Newsletter />
      <Footer />
    </>
  );
};
export default ContactUs;
