import React, { useEffect, useState }  from 'react';
import './index.css';
import apiService from '../../../Common/ApiFactory/apiService';
import SpinnerLoader from '../../Loader/SpinnerLoader';
import FadeLineLoader from '../../../assets/img/loader/Fading-line.gif'
const Booking =()=>{
    const[bookingList,setBookingList]=useState([]);
    const[searchKeyWord,setSearchKeyWord]=useState('');
    const [showLoader,setShowLoader]=useState(false);
    const [showBtnLoder,setShowBtnLoder]=useState(false);
    const [buttonDisabled,setButtonDisabled]=useState(false);
    const handleChange=(e)=>{
        const {value}=e.target;
        clearErrMsg();
        if(value.length < 16)setSearchKeyWord(value.replace(/[^0-9]/g, ''))
    }
    const handleGetBookingDetails=async()=>{
          clearErrMsg();
          if(searchKeyWord.trim() === ''){
                document.getElementById('msg').innerHTML="This Field Can not be blank..!!"
                document.getElementById('inptBox').classList.add('validation-error');
            }
          else{
             try{
                    setShowBtnLoder(true);
                    setButtonDisabled(true);
                    const {responseValue,status,message}=await apiService.get(`User/BookingDetails?SearchKeyWord=${searchKeyWord}`);
                    if(status === 1){
                        setBookingList(responseValue);
                    }
                    else{
                            document.getElementById('msg').innerHTML=message;
                            setBookingList([]);
                    }
                    setShowBtnLoder(false);
                    setButtonDisabled(false);
                }
             catch(error){
                    console.error('Error',error);
                    setShowBtnLoder(false);
                    setButtonDisabled(false);
                    document.getElementById('msg').innerHTML="Something went wrong Please try again"
                }
            }
    };
    const clearErrMsg=()=>{
        document.getElementById('msg').innerHTML=""
        document.getElementById('inptBox').classList.remove('validation-error');
    }
    return(
        <>
           
                    
                    <h3 className='mt-2 ms-2'>My bookings</h3>
                    <div className='row'>
                        <div className="input-container mt-2">
                            <input type="text" className="search-input" id='inptBox'  name="search" placeholder="Enter Booking No or Mobile No..." value={searchKeyWord} onChange={handleChange}/>
                            <button type="submit" className="search-submit" name="submit" onClick={handleGetBookingDetails} disabled={buttonDisabled} title='Click to Search'>
                               
                                {showBtnLoder ?  <img src={FadeLineLoader} className='btn-loader' alt='loader' /> : <i className="fa fa-search" aria-hidden="true"></i>  }
                            </button>
                        </div>
                        <span id='msg' className='text-danger ms-1'></span>
                    </div>
                    {showLoader ? <SpinnerLoader /> :''} 
                    <div className='row'>
                    {bookingList && bookingList.map((val,ind)=>{
                        return(
                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 mb-0'>
                                <div class="card pb-5">
                                    <div className='card-header'>
                                        <div className='row'>
                                            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                                                <h6 className='bookingHeadDate'>Booking No - <b>{val.booking_no}</b></h6>
                                                <h6 className='bookingHeadDate mt-1'>Booking Date - <b>{val.booking_date}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="card-body pb-2">
                                        <h5 class="card-title">{val.product_name}</h5><span className='tour-duration ms-1'>({val.duration})</span>
                                        <p className='mt-1'><i className="fas fa-map-marker-alt me-1"></i>{val.location}</p>
                                        <div class="price-section"><div class="td-discounted-price me-2">₹{val.discounted_price}</div><div class="td-base-price">{val.base_price}</div><sub class="mb-1 sub-color">/Adult</sub><span className='ms-2 discountPercentage'>{val.discount_percentage}<span className='ms-2 discountPercentage'>off</span></span></div>
                                        <div className='row'>
                                            <div className='col-xl-6 col-lg-6 col-md-12 mb-2'>
                                                <p className='details-heading'>Booking Status : <span className='text-warning ms-2'>{val.booking_status}</span></p>
                                                <p className='details-heading'>Travel Date : <span className='ms-2 details-data'>{val.travel_date}</span></p>
                                                <p className='details-heading'>Total Person : <span className='ms-2 details-data'>{val.trveller_count}</span></p>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-12 mb-2'>
                                            <p className='details-heading'>Name : <span className='ms-2 details-data'>{val.name}</span></p>
                                            <p className='details-heading'>Mobile No : <span className='ms-2 details-data'>{val.mobile_no}</span></p>
                                            <p className='details-heading'>Message : <span className='ms-2 details-data'>{val.message}</span></p>
                                           
                                            </div>
                                        </div>
                                        <p class="card-text"></p>
                                        
                                    </div>
                                    <div class="card-footer text-muted">
                                        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
                                            <div class="priceBlock-base-priceHeader">PRICE DETAILS (1 Item)</div>
                                            <div class="priceDetail-base-row mt-3">
                                                <span class="priceDetail-base-heading">Total MRP</span>
                                                <span class="priceDetail-base-value">
                                                        <span class="">₹</span> 3,999
                                                </span>
                                            </div>
                                           
                                            <div class="priceDetail-base-row mt-3">
                                                <span class="priceDetail-base-heading">Discount on MRP</span>
                                                <span class="priceDetail-base-value discount">
                                                        <span class="">₹</span> 3,999
                                                </span>
                                            </div>
                                            <div class="priceDetail-base-row mt-3 mb-5">
                                                <span class="priceDetail-base-heading total-amount">Total Amount</span>
                                                <span class="priceDetail-base-value">
                                                        <span class="">₹</span> 3,999
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    </div>
           
               
        </>
    )
}
export default Booking;
