import React from 'react'
import url from '../Common/url'
import CommunicationHub from '../Common/CommunicationHub'
import { Link } from 'react-router-dom'
export default function Footer(){
    return(
        <>
            <footer id="footer_area">
        <div class="container">
            <div class="row cus-text-black">
                <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>Need any help?</h5>
                    </div>
                    <div class="footer_first_area">
                        <div class="footer_inquery_area">
                            <h5>Call 24/7 for any help</h5>
                            <h3> <Link to={`tel:${CommunicationHub.landLineNo}`}>{CommunicationHub.landLineNo}</Link></h3>
                        </div>
                        <div class="footer_inquery_area">
                            <h5>Mail to our support team</h5>
                            <h3> <Link to={`mailto:${CommunicationHub.supportMail}`}>{CommunicationHub.supportMail}</Link></h3>
                        </div>
                        <div class="footer_inquery_area">
                            <h5>Follow us on</h5>
                            <ul class="soical_icon_footer">
                                <li><Link to="https://www.facebook.com/Travel-Right-115728023216903/" target="_blank"><i className="fab fa-facebook"></i></Link></li>
                                <li><Link to="https://www.instagram.com/travelright_lko/?gshid=YmMyMTA2M2Y"><i className="fab fa-instagram" target="_blank"></i></Link></li>
                                <li><Link to="https://wa.me/9935775799" target="_blank"><i className="fab fa-whatsapp "></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 offset-lg-1 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>About Company</h5>
                    </div>
                    <div class="footer_link_area">
                            <h6>
                            We are a one-stop travel agency dedicated to creating seamless travel experiences, whether you’re planning a short getaway or an extended adventure. We cater to solo travelers and groups alike, offering personalized assistance to ensure every journey is memorable and worry-free. Our extensive experience and commitment to service make us a trusted choice, with many travelers returning to explore the world with us. Additionally, we provide comprehensive flight ticketing options to suit any travel plan.
                            </h6>
                    </div>
                </div>
                <div class="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>Services</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul className='pl-0'>
                            <li><Link to="/tour-list" className='fw-500'>Umrah</Link></li>
                            <li><Link to="/tour-list" className='fw-500'>Domestic Tour</Link></li>
                            <li><Link to="/tour-list" className='fw-500'>International Tour</Link></li>
                            <li><Link to="/search-flight" className='fw-500'>Flight Ticket</Link></li>
                            <li><Link to="/tour-list" className='fw-500'>Holidays</Link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-12">
                    <div class="footer_heading_area">
                        <h5>Company</h5>
                    </div>
                    <div class="footer_link_area">
                        <ul className='pl-0'>
                            <li><Link to="/about-us" className='fw-500'>About Us</Link></li>
                            <li><Link to="/contact-us" className='fw-500'>Contach Us</Link></li>
                           
                        </ul>
                    </div>
                </div>
                
                
            </div>
        </div>
    </footer>
    
        </>
    )
}