import React, { useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Header from '../../Header';
import ScrollToTop from '../../scrollToTop';
import SliderComponent from '../../ImageSlider';
import './CustomCss.css';
import StarRating from '../../Rating';
import Loader from '../../../Loader/Loader';
import apiService from '../../../Common/ApiFactory/apiService';
import UseResetScroll from '../../../Common/AutoScroll/UseResetScroll';
import FadeLineLoader from '../../../assets/img/loader/Fading-line.gif'
import { Button, Modal } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import BookingConfirmationModal from '../../Modal';

const TourDetails = ()=>{
    const [showLoader,setShowLoader]=useState(0);
    const [packageDetails,setPackageDetails]=useState([]);
    const [tourDurationList,setTourDurationList]=useState([]);
    const [itineraryList,setItineraryList]=useState([]);
    const [mediaList,setMediaList]=useState([]);
    const [packageDetailsStore,setPackageDetailsStore]=useState([]);
    const [dateValue,setDateValue]=useState(new Date());
    const [isDisabledBtn,setisDisabledBtn]=useState(false);
    const [showBtnLoder,setShowBtnLoder]=useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [productId, setProductId] = useState(0);
    const [showConfirmBookingModal, setShowConfirmBookingModal] = useState(0);
    const [bookingDetails, setBookingDetails] = useState({});
    const [showBtnLoderPostComment,setShowBtnLoderPostComment]=useState(false);
    const [disabledPostCommentBtn,setDisabledPostCommentBtn]=useState(false);
    const [ratingDetails,setRatingDetails]=useState([]);
    const [sendForm,setSendForm]=useState({
        name:'',
        email:'',
        mobileNo:'',
        travelDate:null,
        person:'',
        message:'',
        tourId:0,
        tourDuration:0
    });
    const[reviewForm,setReviewForm]=useState({
        name:'',
        review:'',
        rating:0,
        productId:0,
    })
    // const [itneraryList,setItneraryList]=useState([
    //     {
    //         day:'Day 1',
    //         heading:'Pick Up',
    //         details:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form'
    //     },
    //     {
    //         day:'Day 2',
    //         heading:'Reached',
    //         details:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form'
    //     },
    //     {
    //         day:'Day 3',
    //         heading:'Hotel',
    //         details:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form'
    //     },
    //     {
    //         day:'Day 4',
    //         heading:'Drop',
    //         details:'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form'
    //     },
    // ]);
    const [reviewList,setReviewList]=useState([]);
    const toggleDatePicker = () => {
        setIsDatePickerOpen((prev) => !prev); // Toggle date picker open state
    };
    const handleCheckRequestUrl=()=>{
        const urlString = window.location;
        const url = new URL(urlString);
        const param= new URLSearchParams(url.search);
        if(param.size){
            const key = param.get("product-key");
            if(key){
                try{
                    const decodedString=atob(key);
                    setProductId(decodedString);
                    getProductDetails(decodedString);
                }
                catch(error){
                    console.error('window.URL Package-details',error)
                }
            }
            else{
                console.error('Invalid Url Key',key)
            }
        }
        
    };
    const getProductDetails=async(param)=>{
        try{
            setShowLoader(1);
            const {responseValue,status}= await apiService.get(`User/GetProductDetails?ProductId=${param}`);
            if(status===1){
                const duration=JSON.parse(responseValue[0].durationList) ?? [];
                const itinerary=JSON.parse(responseValue[0].itinerary) ?? [];
                const media=JSON.parse(responseValue[0].mediaUrls) ?? [];
                setPackageDetailsStore(responseValue);
                setPackageDetails(responseValue[0]);
                setTourDurationList(duration);
                setItineraryList(itinerary);
                setMediaList(media[0]);
                const reviewListArr=JSON.parse(responseValue[0].reviewList) ?? [];
                const ratingAvgArr=JSON.parse(responseValue[0].ratingAvg) ?? [];
                setReviewList(reviewListArr);
                setRatingDetails(ratingAvgArr.length > 0 ? ratingAvgArr[0]:[]);
                setTimeout(()=>{
                    setShowLoader(0);
                },700);
            }
        }
        catch(err){
                console.error('Trending-tour-slider',err)
                setShowLoader(0);
        }
        
        
    };
    const handleChangeDuration =()=>{
        setShowLoader(1);
        try{
            const duration = document.getElementById('tourDurationddl').value;
            const tempArr=[...packageDetailsStore];
            const getPackageDetails=tempArr.filter((arr)=> arr.duration_id === parseInt(duration ?? 0));
            const itinerary=JSON.parse(getPackageDetails[0].itinerary) ?? [];
            setPackageDetails(getPackageDetails[0]);
            setItineraryList(itinerary);
            setTimeout(()=>{
                setShowLoader(0);
            },900)
        }
        catch(error){
            console.error('Tour-details Err fn handleChangeDuration err_msg',error);
            setShowLoader(0);
        }
    };
    const handleChange =(e)=>{
        const {name,value}=e.target;
        clearValidationMsg();
        if(name === 'mobileNo' || name === 'person' ){
            setSendForm((prev)=>({
                ...prev,
                [name]:value.replace(/[^0-9]/g, '')
            }));
        }
        else{
            setSendForm((prev)=>({
                ...prev,
                [name]:value
            }));
        }
        
    };
    const convertDateIntoYYYYMMDD=(value)=>{
        if(!value) return null;
        try{
            const date = new Date(value);
            const year=date.getFullYear();
            const month = String(date.getMonth()+1).padStart(2,'0');
            const day = String(date.getDate()).padStart(2,'0');
            return `${year}-${month}-${day}`;
        }
        catch(error){
            console.error('Convert Date into YYYY-MM-DD',error);
            return null;
        }
        
    }
    const validateForm=(data)=>{
        let errors = [];
        if (!data.name || data.name.trim() === "") {
            errors.push("errName");
        }
        else if (!data.mobileNo || !/^\d{10}$/.test(data.mobileNo)) {
            errors.push("errMobileNo");
        }
        else if (!data.travelDate || isNaN(Date.parse(data.travelDate))) {
            errors.push("errTravelDate");
        }
        else if (!data.person || isNaN(data.person) || data.person <= 0) {
            errors.push("errPerson");
        }
        return errors;
    };
    const clearValidationMsg=()=>{
        document.getElementById('errName').classList.remove('is-invalid');
        document.getElementById('errMobileNo').classList.remove('is-invalid');
        document.getElementById('errTravelDate').classList.remove('is-invalid');
        document.getElementById('errPerson').classList.remove('is-invalid');
        document.getElementById('validationMsg').classList.add('d-none');
    };
    const clearErrorMsg=()=>{
        document.getElementById('errorMsg').innerHTML='';
        document.getElementById('errorMsg').classList.add('d-none');
    };
    const handleClear=()=>{
        setSendForm({
            name:'',
            email:'',
            mobileNo:'',
            travelDate:null,
            person:'',
            message:'',
            tourId:0,
            tourDuration:0
        });
    };
    const clearReviewErrorMsg=()=>{
        document.getElementById('errReviewName').innerHTML="";
        document.getElementById('reviewName').classList.remove('is-invalid');
        document.getElementById('errRating').innerHTML="";
        document.getElementById('ddlRating').classList.remove('is-invalid');
        document.getElementById('errReview').innerHTML="";
        document.getElementById('reviewMsg').classList.remove('is-invalid');
        document.getElementById('responsePostReview').innerHTML="";
        document.getElementById('responsePostReview').classList.add('d-none');
    };
    const clearReviewForm=()=>{
        document.getElementById('ddlRating').value=0;
        setReviewForm({
            name:'',
            review:'',
            rating:0,
            productId:0,
        });
    }
    const handleBookTour=async()=>{
        clearErrorMsg();
        const tourDuration=document.getElementById('tourDurationddl').value;
        const basePrice=document.getElementById('basePrice').innerHTML;
        const obj={
            ...sendForm,
            tourId:productId,
            tourDuration:tourDuration ?? 0,
            travelDate:convertDateIntoYYYYMMDD(selectedDate),
            price:parseFloat(basePrice) ?? 0
        };

        
        const error = validateForm(obj);
        if(error.length > 0){
            document.getElementById(error[0]).classList.add('is-invalid');
            document.getElementById('validationMsg').classList.remove('d-none');
        }
        else{
            setisDisabledBtn(true);
            setShowBtnLoder(true);
            try{
                const {status,responseValue}= await apiService.post('User/BookingRequest',obj);
                if(status === 1){
                    setBookingDetails(responseValue[0]);
                    setShowConfirmBookingModal(1);
                    handleClear();
                }
                else{
                    document.getElementById('errorMsg').innerHTML=responseValue;
                    document.getElementById('errorMsg').classList.remove('d-none');
                }
                setisDisabledBtn(false);
                setShowBtnLoder(false);

            }
            catch(error){
                console.error('Error Booking Request',error);
                setisDisabledBtn(false);
                setShowBtnLoder(false);
                document.getElementById('errorMsg').innerHTML='Our servers are currently overloaded. Please refresh the page or try again.';
                document.getElementById('errorMsg').classList.remove('d-none');
            }
        }
    };
    const handleChangeReview=(e)=>{
        const {name,value}=e.target;
        clearReviewErrorMsg();
        setReviewForm((prev)=>({
            ...prev,
            [name]:value
        }))
    };
    const handlePostReview=async(e)=>{
        e.preventDefault();
        const ddlRating=document.getElementById('ddlRating').value;
        clearReviewErrorMsg();
        const obj={
            ...reviewForm,
            rating:ddlRating,
            productId:productId
        };
        if(obj.name.trim() === '' || obj.name.trim().length === 0){
            document.getElementById('errReviewName').innerHTML="Fill Name..";
            document.getElementById('reviewName').classList.add('is-invalid');
        }
        else if(ddlRating === "0" || ddlRating === null){
            document.getElementById('errRating').innerHTML="Choose Rating..";
            document.getElementById('ddlRating').classList.add('is-invalid');
        }
        else if(obj.review.trim() === '' || obj.review.trim().length === 0){
            document.getElementById('errReview').innerHTML="Fill Comment..";
            document.getElementById('reviewMsg').classList.add('is-invalid');
        }
        else{
            setDisabledPostCommentBtn(true)
            setShowBtnLoderPostComment(true);
                try{
                    const {status}= await apiService.post('User/PostProductReview',obj);
                    if(status === 1){
                        document.getElementById('responsePostReview').classList.remove('d-none');
                        document.getElementById('responsePostReview').classList.add('post-review-success');
                        document.getElementById('responsePostReview').innerHTML="Grateful for your review! 🙏";
                        clearReviewForm();
                        setTimeout(()=>{
                            clearReviewErrorMsg();
                        },10000)
                    }
                    setDisabledPostCommentBtn(false)
                    setShowBtnLoderPostComment(false);
                }
                catch(error){
                    console.error('Error Post Review',error);
                    document.getElementById('responsePostReview').classList.remove('d-none');
                    document.getElementById('responsePostReview').classList.add('post-review-error');
                    document.getElementById('responsePostReview').innerHTML="Something went wrong please try again..!!";
                    setDisabledPostCommentBtn(false)
                    setShowBtnLoderPostComment(false);
                }
                
        }
    };
    UseResetScroll();
    useEffect(()=>{
        handleCheckRequestUrl();
    },[])
      
    return(
        <>
            <Header customizedHeader={1} />
            {/* <CommonNavbarBanner heading="Tour Details" menuName="Tour Details" link="/" /> */}
            <section id="tour_details_main" className="section_padding ">
                <div className="container cus-mt-20">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="tour_details_leftside_wrapper">
                                <div className="tour_details_heading_wrapper">
                                    <div className="tour_details_top_heading">
                                        <h2>{packageDetails.product_name}</h2>
                                        <h5><i className="fas fa-map-marker-alt"></i> {packageDetails.location}</h5>
                                    </div>
                                    <div className="tour_details_top_heading_right">
                                        <h4>Rating</h4>
                                        <h6>{ratingDetails.AverageRating || 4/5}</h6>
                                        {/* <p>(1214 reviewes)</p> */}
                                    </div>
                                </div>
                                <SliderComponent images={mediaList}/>
                                <div className="tour_details_boxed">
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-6'>
                                        <div class="tour_package_details_bar_price">
                                                <h5>Price</h5>
                                                <span className="discount-percentage ms-2">{packageDetails.discount_percentage} off</span>
                                                <div class="tour_package_bar_price">
                                                    <div className="price-section" style={{fontSize:'20px'}}>
                                                        <div className="td-discounted-price me-2" >₹<span id='basePrice'>{packageDetails.discounted_price}</span></div>
                                                        <div className="td-base-price">₹{packageDetails.base_price}</div><sub className='mb-1 sub-color'>/Adult</sub>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-6'>
                                        <div class="tour_package_details_bar_price">
                                                <h5>Choose Trip Duration</h5>
                                                <div class="tour_package_bar_price">
                                                    <select className='form-control bg_input cus-cursor-pointer' id='tourDurationddl' onChange={handleChangeDuration}>
                                                        {tourDurationList && tourDurationList.map((duration,ind)=>{
                                                            return(
                                                                <option value={duration.duration_id} key={ind+1}>{duration.duration}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tour_details_boxed">
                                    <h3 className="heading_theme">Package Details</h3>
                                    <div className="tour_details_boxed_inner" dangerouslySetInnerHTML={{__html:packageDetails.product_description}}></div>
                                </div>
                                <div className="tour_details_boxed">
                                    <h3 className="heading_theme">Day Wise Itinerary</h3>
                                    <div className="tour_details_boxed_inner">
                                        <div className="accordion" id="accordionExample">
                                            {itineraryList && itineraryList.map((val,ind)=>{
                                                return(
                                                    <>
                                                        <div className="accordion_flex_area">
                                                            <div className="accordion_left_side">
                                                                <h5>Day{val.day}</h5>
                                                            </div>  
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id={`heading${ind}`}>
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${ind}`} aria-expanded="true" aria-controls={`collapse${ind}`}>
                                                                    
                                                                    </button>
                                                                </h2>
                                                                <div id={`collapse${ind}`} className="accordion-collapse collapse show"
                                                                    aria-labelledby={`heading${ind}`} data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body">
                                                                        <div className="accordion_itinerary_list" dangerouslySetInnerHTML={{__html:val.details}}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="tour_details_right_sidebar_wrapper cus-price-box">
                                <div className="tour_detail_right_sidebar">
                                    <div className="tour_details_right_boxed">
                                        <div className="tour_package_details_bar_price">
                                                <h5>Booking Request</h5>
                                        </div>
                                        <div className="tour_package_details_bar_list">
                                            <form id="contact_form_content">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control bg_input" id='errName' placeholder="Full Name*" name="name" value={sendForm.name} onChange={handleChange}/>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                        <input type="email" className="form-control bg_input"  placeholder="Email (Optional)" name="email" value={sendForm.email} onChange={handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                        <input type="text" className="form-control bg_input" id='errMobileNo' placeholder="Your Phone*" name="mobileNo" value={sendForm.mobileNo} onChange={handleChange} maxlength="10"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group " title='Travel Date'>
                                                            <DatePicker selected={selectedDate} onChange={(date) => {setSelectedDate(date); setIsDatePickerOpen(false);  }}
                                                            onClickOutside={() => setIsDatePickerOpen(false)}  onSelect={() => setIsDatePickerOpen(false)} onInputClick={toggleDatePicker} open={isDatePickerOpen} className="form-control bg_input" id='errTravelDate' value={sendForm.travelDate} placeholderText="Travel Date" />
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group">
                                                        <input type="text" className="form-control bg_input" id='errPerson' placeholder="Traveller Count*" name="person" title='Traveller Count' value={sendForm.person} onChange={handleChange} maxlength="3" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                        <textarea className="form-control bg_input" rows="5" placeholder="Message.." name="message" value={sendForm.message} onChange={handleChange}></textarea>
                                                        </div>
                                                    </div>
                                                    <small className='text-danger d-none' id='validationMsg'>Oops! You missed some required fields. Please fill them in.</small>
                                                    <small className='text-danger d-none' id='errorMsg'></small>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="tour_select_offer_bar_bottom">
                                    <button className="btn cus-btn-theme btn_md w-100" onClick={handleBookTour} disabled={isDisabledBtn}>
                                    {showBtnLoder ?  <img src={FadeLineLoader} className='btn-loader' alt='loader' /> :<span>Book Now</span>   }
                                    </button>
                                    
                                    </div>
                                </div>
                            
                                <div className="tour_detail_right_sidebar">
                                    <div className="tour_details_right_boxed">
                                        <div className="tour_details_right_box_heading">
                                            <h3>Why choose us</h3>
                                        </div>

                                        <div className="tour_package_details_bar_list first_child_padding_none">
                                            <ul>
                                                <li><i className="fas fa-circle"></i>Trusted customer service you can rely on, 24/7</li>
                                                <li><i className="fas fa-circle"></i>Customised Itineraries</li>
                                                <li><i className="fas fa-circle"></i>Wallet-Friendly Prices</li>
                                                <li><i className="fas fa-circle"></i>Exciting Deals</li>
                                                <li><i className="fas fa-circle"></i>Comfortable and hygienic vehicle</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="write_your_review_wrapper">
                                <h3 className="heading_theme">Write your review</h3>
                                <div className="write_review_inner_boxed">
                                    <form id="news_comment_form">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-froup">
                                                    <input type="text" className="form-control bg_input"
                                                        placeholder="Enter full name" name='name' value={reviewForm.name} id='reviewName' onChange={handleChangeReview}/>
                                                        <small className='text-danger' id="errReviewName"></small>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-froup" tooltip="Choose Rating">
                                                    <select placeholder="Select Rating" className='form-control' id='ddlRating' onChange={clearReviewErrorMsg}>
                                                        <option value="0">Choose Rating</option>
                                                        <option value="1">1 Start</option>
                                                        <option value="2">2 Start</option>
                                                        <option value="3">3 Start</option>
                                                        <option value="4">4 Start</option>
                                                        <option value="5">5 Start</option>
                                                    </select>
                                                    <small className='text-danger' id="errRating"></small>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-froup">
                                                    <textarea rows="6" placeholder="Write your comments"
                                                        className="form-control bg_input" name='review' value={reviewForm.review} id='reviewMsg' onChange={handleChangeReview}></textarea>
                                                        <small className='text-danger' id="errReview"></small>
                                                </div>
                                                <div className='text-center d-none mb-2' id='responsePostReview'></div>
                                                <div className="comment_form_submit">
                                                    <button className="btn cus-btn-theme btn_md" onClick={handlePostReview} disabled={disabledPostCommentBtn}>
                                                    {showBtnLoderPostComment ?  <img src={FadeLineLoader} className='btn-loader' alt='loader' /> : <span>Post comment </span>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="all_review_wrapper">
                                <h3 className="heading_theme">All review</h3>
                            </div>
                        </div>
                    {reviewList && reviewList.map((val,ind)=>{
                            return(
                                <>
                                <div className="col-lg-4 col-md-6" key={ind}>
                                    <div className="all_review_box">
                                        <div className="all_review_date_area">
                                            <div className="all_review_date">
                                                <h5>{val.reviewDate}</h5>
                                            </div>
                                            <div className="all_review_star">
                                                <h5>Rating</h5>
                                                <StarRating rating={val.rating} />
                                                
                                            </div>
                                        </div>
                                        <div className="all_review_text mt-0">
                                            <h4>{val.name}</h4>
                                            <div className='review-container'>
                                                <p>{val.review}</p> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            )
                    })}
                    </div>
                </div>
                 {showConfirmBookingModal === 1 ? <BookingConfirmationModal isShowModal={showConfirmBookingModal} data={bookingDetails}/> :''}
        
            </section>
            <ScrollToTop />
            {showLoader ? <Loader showLoader={showLoader} /> :''}
        </>
    )
}
 export default TourDetails;