import React, { useEffect, useState } from 'react'
import apiService from '../Common/ApiFactory/apiService';
import '../CustomCSS/TopDestination.css';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from './Loader/SpinnerLoader';
export default  function TopDestinations(){
    const navigate =useNavigate();
    const [showLoader,setShowLoader]=useState(false);
    const [destinationList,setDestinationList]=useState([]);

    const getDestinstionList= async()=>{
        setShowLoader(true);
        try{
            const {status,responseValue}= await apiService.get(`Admin/GetDestinationList`);
            if (status === 1) setDestinationList(responseValue);
            setShowLoader(false);
           
        }
        catch(error){
            setShowLoader(false);
            console.error("Error fn GetDestination from destinationmaster err->".error);
        }
    };
    const handleExploreDestination=async (param)=>{
        console.log('param',param);
        const encodedString=btoa(param.id);
        const urlParam=`?name=${param.destination}`;
        const urlParam2=`&destination-key=${encodedString}`;
        const searchUrl=`/explore-destination${urlParam}${urlParam2}`;
        navigate(searchUrl);
    }
    useEffect(()=>{
        getDestinstionList();
    },[])
    return(
        <>
            <section id="top_destinations" class="section_padding_top">
        <div class="container">
            {/* <!-- Section Heading --> */}
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>Top Trending Destinations</h2>
                        <span> Explore the hottest travel spots around the globe and experience the best of holidays.</span>
                    </div>
                </div>
            </div>
            <div class="row">
                {destinationList && destinationList.map((destination,ind)=>{
                    return(
                        <>
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12" key={ind+1}>
                                <div class="destinations_content_box img_animation destinations_content_box_cus">
                                    <img src={destination.imagePath} alt="img" />
                                    <div class="destinations_content_inner">
                                        <h2>{destination.destination || ''}</h2>
                                        <span class="btn btn_theme btn_md" onClick={()=>{handleExploreDestination(destination)}}>Explore</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
            {showLoader ? <SpinnerLoader /> : ''}
        </div>
    </section>
        </>
    )
}