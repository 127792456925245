import React, { useEffect, useState } from "react";
import Header from "../Header";
import { json, Link, useNavigate } from "react-router-dom";
import url from "../../Common/url";
import FadeLineLoader from '../../assets/img/loader/Fading-line.gif';
import '../Snackbar/style.css'
import Common from "../../Common/Common";
import apiService from "../../Common/ApiFactory/apiService";
const Login =()=>{
    document.title="login";
    const navigate = useNavigate();
    const[showBtnLoder,setShowBtnLoder] = useState(false);
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const[sendForm,setSendForm]=useState({
        userLoginID:"",
        password:"",
        loginType:1,
    });
    const validationForm=(data)=>{
        const validationRes={key:'',isValid:true,message:"success"};
        if (!data.userLoginID.trim()) {
            validationRes.key = "userLoginID";
            validationRes.isValid = false;
            validationRes.message="This field is required.";
            
        }
        else if (!data.password.trim()) {
            validationRes.key = "password";
            validationRes.isValid = false;
            validationRes.message="This field is required.";
        }
        
        
        return validationRes; 
    };
    const handleChange=(e)=>{
        const {value,name}=e.target;
         const targetElement=document.getElementById(`${name}`);
         targetElement.classList.remove('is-invalid');
        if (name === "mobileNumber") {
            if(value.length < 11){
                const numericValue = value.replace(/[^0-9]/g, '');
                setSendForm((prev) => ({
                    ...prev,
                    [name]: numericValue 
                }));
            }
            
        } else {
            setSendForm((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };
    const handleLogin=async(e)=>{
        e.preventDefault();
        const validation=validationForm(sendForm);
        if(validation.isValid){
            const loginType = Common.checkEmailOrMobile(sendForm.userLoginID);
            
            const obj={
                "loginID": sendForm.userLoginID,
                "loginType": loginType.code,
                "userType": sendForm.loginType,
                "password": sendForm.password
            }
            setShowBtnLoder(true);
            const {status,message,responseValue} = await apiService.post('Authentication/UserLogin',obj);
            if(status === 1){
                    setShowBtnLoder(false);
                    setSnackbarMessage("Login Successfully.!");
                    setIsSnackbarVisible(true);
                    const jsonString=JSON.stringify(responseValue[0]);
                    const encodedString=Common.encodedData(jsonString);
                     localStorage.setItem('isLoggedIn',true);
                     localStorage.setItem('data',encodedString);
                    setTimeout(()=>{
                        setIsSnackbarVisible(false);
                        navigate(url.homePage);
                    },1500);
            }
            else{
                    setShowBtnLoder(false);
                    setSnackbarMessage(message);
                    setIsSnackbarVisible(true);
                    setTimeout(()=>{
                        setIsSnackbarVisible(false)
                    },2000)
            }
            
        }
        else{
            const targetElement=document.getElementById(`${validation.key}`);
            targetElement.classList.add('is-invalid');
            targetElement.focus();
            setSnackbarMessage(validation.message);
            setIsSnackbarVisible(true);  
            setTimeout(()=>{
                setIsSnackbarVisible(false)
            },3000)
            

        }
    };
    useEffect(()=>{
        const isLogin = Common.isUserAuthenticated();
        if(isLogin){
            navigate(url.homePage);
        }
        
    },[])
    return(
        <>
            <Header customizedHeader={1} />
            <section id="common_author_area" class="section_padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="common_author_boxed">
                                <div class="common_author_heading">
                                    <h3>Login your account</h3>
                                </div>
                                <div class="common_author_form">
                                    <form action="#" id="main_author_form">
                                        <div className="form-floating mb-2">
                                            <select className="form-control" id="loginType" name="loginType" placeholder="Login Type" onChange={handleChange}>
                                                <option value="1">User</option>
                                                <option value="4">Partner</option>
                                            </select>
                                            <label for="loginType" className="custom-input-font-style">Login Type</label>
                                        </div>
                                        <div className="form-floating mb-2">
                                            <input type="text" name="userLoginID" value={sendForm.userLoginID} className="form-control" id="userLoginID" placeholder="User Id" onChange={handleChange} />
                                            <label for="userLoginID" className="custom-input-font-style">Mobile or Email</label>
                                        </div>
                                        
                                        <div className="form-floating mb-2">
                                            <input type="password" name="password" value={sendForm.password} className="form-control" id="password" placeholder="password" onChange={handleChange} />
                                            <label for="password" className="custom-input-font-style">Password</label>
                                        </div>
                                        <div class="common_form_submit">
                                            <button class="btn btn_theme btn_md" disabled={showBtnLoder} onClick={handleLogin}>
                                            {showBtnLoder ?  <img src={FadeLineLoader} className='btn-loader' alt='loader' /> :<span> Log in</span>   }
                                               
                                            
                                            </button>
                                        </div>
                                        <div class="have_acount_area">
                                            <p>Dont have an account? <Link to={url.signup}>SignUp now</Link></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="snackbar" className={isSnackbarVisible ? "show" : ""}> {snackbarMessage} </div>
            </section>
        </>
    )
}
export default Login;