import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routing from './Routing/Routes';
import 'bootstrap-icons/font/bootstrap-icons.css';
function App() {
  return (
    <>
     <Routing />
    </>
  );
}

export default App;
