import React, { useEffect, useState } from "react";
import apiService from "../Common/ApiFactory/apiService";
import SpinnerLoader from "./Loader/SpinnerLoader";
import { useNavigate } from "react-router-dom";
export default function ProductDisplay(){
    const navigate = useNavigate();
    const [CategoryList,setCategoryList]=useState([]);
    const [packageList,setPackageList]=useState([]);
    const [showLoader,setShowLoader]=useState(false);
    const [activeTabID,setActiveTabID]=useState(0);
    const [activeTabName,setActiveTabName]=useState('');
    const getCategory=async()=>{
          setShowLoader(true)
        try{
            const {responseValue,status}= await apiService.get("Admin/GetCategoryList");
            if(status ===1){
                setCategoryList(responseValue);
                console.log('responseValue category',responseValue)
                if(responseValue.length > 0){
                    setActiveTabID(responseValue[0].id);
                    setActiveTabName(responseValue[0].category_name);
                    getPackageList(responseValue[0].id);
                }
            }
        }
        catch(error){
            console.error('Product-display',error)
        }
    };
    const getPackageList=async(key)=>{

        try{
            
            const {responseValue,status}= await apiService.get(`User/GetProductByCategory?CategoryId=${key}`);
            if(status ===1){
                setPackageList(responseValue);
            }
            else{
                setPackageList([]);
            }
            setShowLoader(false);
        }
        catch(error){
            console.error('Product-display',error)
        }
    };
    const handleClick =(param)=>{
        console.log('param',param)
        if(activeTabID !== param.id){
            setActiveTabID(param.id);
            setActiveTabName(param.category_name);
            setShowLoader(true);
            getPackageList(param.id);
        }
    };
    const handleViewPackage=(param)=>{
        const encodedString=btoa(param.product_id);
        const urlParam=`product-key=${encodedString}`;
        const searchUrl=`/tour-details?${urlParam}`;
        navigate(searchUrl);
    };
    const handleViewMore =()=>{
        const encodedString=btoa(activeTabID);
        const urlParam=`?name=${activeTabName}`;
        const urlParam2=`&category=${encodedString}`;
        const searchUrl=`/best-deals${urlParam}${urlParam2}`;
        console.log('ssssss',searchUrl)
        navigate(searchUrl);
    }
    useEffect(()=>{
        getCategory();
    },[])
    return(
        <>
            <section id="explore_area" className="section_padding_top">
        <div className="container">
            {/* <!-- Section Heading --> */}
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section_heading_center">
                        <h2>Best Deals</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="theme_nav_tab">
                        <nav className="theme_nav_tab_item">
                            <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                {CategoryList && CategoryList.map((category,index)=>{
                                    return(
                                        <>
                                            {index < 5 ?
                                                <button className={`nav-link ${index === 0 ? 'active' :''} ms-1`} id="nav-tab" data-bs-toggle="tab" 
                                                data-bs-target="#nav-container" type="button" role="tab" aria-controls="nav-tab" aria-selected="true" onClick={()=>{handleClick(category)}}>
                                                {category.category_name}
                                                </button>
                                            
                                            :''}
                                        </>
                                    )
                                })}
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-container" role="tabpanel"
                            aria-labelledby="nav-tab">
                            <div className="row">
                                    {packageList && packageList.map((val,ind)=>{
                                        return(
                                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12" key={ind+1}>
                                                <div className="theme_common_box_two img_hover card c-pointer cm-0">
                                                    <div className="theme_two_box_img card-img" >
                                                    <img src={val.media_url} alt="img" />
                                                        <p><i className="fas fa-map-marker-alt"></i>{val.location}</p>
                                                    </div>
                                                    <div className="theme_two_box_content card-content" >
                                                        <h4>{val.product_name}</h4>
                                                        <p><span className="review_rating">4.8/5 Excellent</span>  </p>
                                                        <div className="price-section">
                                                            <div className="discounted-price me-2">₹{val.discounted_price}</div>
                                                            <div className="base-price">₹{val.base_price}</div>
                                                            <span className="discount-percentage">{val.discount_percentage} off</span>
                                                        </div>
                                                    </div>
                                                    <div className="cruise_content_bottom_right jutify-content-center c-card-button">
                                                        <button className="btn btn_theme btn_md col-12" onClick={()=>{handleViewPackage(val)}}>View</button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        )
                                    })}
                                    {packageList.length > 4 ?
                                        <div className="cruise_content_bottom_right jutify-content-center c-card-button">
                                            <button className="btn btn_theme btn_md" onClick={handleViewMore}>View More</button>
                                        </div>
                                    :''}
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            {showLoader ? <SpinnerLoader /> :''}
        </div>
    </section>
        </>
    )
}